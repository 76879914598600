import { Component, Inject, Optional, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import { WashItemService } from '../../wash-list/wash-item/wash-item.service';
import { BusinessPartner } from '../../wash-list/wash-list.model';

@Component({
  selector: 'app-wash-convert',
  templateUrl: './wash-convert.component.html',
  styleUrls: ['./wash-convert.component.scss'],
})
export class WashConvertComponent implements OnInit, OnDestroy {
  private businessPartner$: Subscription;
  poNumber: string;
  poNumberForPrep: string;
  canConvertWashRequest = false;
  showNotEnoughTimeErorMessage = false;
  businessPartner?: BusinessPartner;
  isProcessing: boolean;
  createNewPrepWashRequest: UntypedFormControl;

  get poNumberPlaceholder() {
    const value =
      this.businessPartner && this.businessPartner.contmngPOFormathelp;
    return value || '';
  }

  get poNumberTip() {
    const value =
      this.businessPartner &&
      this.businessPartner.contmngPOFormathelp &&
      `${this.businessPartner.contmngPOFormathelp}. If the customer will provide the PO number later, enter the word 'pending'.`;
    return value;
  }

  get linkTypeCleanAndPrep() {
    return !!this.data.linkType;
  }

  get createNewPrepWashRequestTip() {
    return this.linkTypeCleanAndPrep
      ? 'Can not create a new request because it is already linked.'
      : `This action will create a second Sales Order for the prep request. Still,
    it must be converted to a Work Order in order to be started.`;
  }

  get disableConvertButton() {
    return !this.canConvertWashRequest || !this.poNumber;
  }

  get showCreateNewPrepWashRequestToggle() {
    return (
      this.data.serviceTypeOld === 'Clean' &&
      this.data.serviceType.key === 'cleanAndPrep'
    );
  }

  constructor(
    public dialogRef: MatDialogRef<WashConvertComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private washListService: WashListService,
    private washItemService: WashItemService,
    private toastr: ToastrService,
    private businessPartnerService: BusinessPartnerService
  ) {}

  ngOnInit() {
    this.poNumber = this.data.originalPo;
    this.washListService
      .checkIfCanConvertWashRequest(
        this.data.washRequestId,
        this.data.serviceType
      )
      .then(
        () => {
          this.canConvertWashRequest = true;
        },
        () => {
          this.showNotEnoughTimeErorMessage = true;
        }
      );

    this.businessPartner$ =
      this.businessPartnerService.businessPartners.subscribe((data) => {
        this.businessPartner = data.find(
          (item) => item.id === this.data.businessPartnerId
        );
      });
    this.createNewPrepWashRequest = new UntypedFormControl({
      value: false,
      disabled: this.linkTypeCleanAndPrep,
    });
  }

  ngOnDestroy() {
    this.businessPartner$.unsubscribe();
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  convertWashRequest() {
    if (
      !this.canConvertWashRequest &&
      this.poNumber &&
      this.data.serviceType.key === 'cleanAndPrep' &&
      !this.poNumberForPrep
    ) {
      return;
    }

    this.isProcessing = true;

    const poValidationResult = this.washItemService.applyPOValidation({
      businessPartner: this.businessPartner,
      poNumber: this.poNumber,
      poNumberForPrep: this.poNumberForPrep,
    });

    if (this.createNewPrepWashRequest.value && !this.poNumberForPrep) {
      this.toastr.error(
        poValidationResult.message,
        'PO for prep is required for new request'
      );
      this.isProcessing = false;
      return;
    }

    // If valid PO, convert wash request
    if (poValidationResult.isValid) {
      this.washListService
        .convertWashRequest(
          this.data.washRequestId,
          this.data.serviceType,
          this.poNumber,
          this.poNumberForPrep,
          this.showCreateNewPrepWashRequestToggle
            ? this.createNewPrepWashRequest.value
            : null
        )
        .then(() => {
          this.isProcessing = false;
          this.dialogRef.close();
        });
      return;
    }
    this.toastr.error(poValidationResult.message, 'Invalid PO Number');
    this.isProcessing = false;
  }
}
