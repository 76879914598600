import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { StartStopConfirmationComponent } from './start-stop-confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class StartStopConfirmationService {
  private dialogBehaviourSubject = new BehaviorSubject(false);
  dialogResult = this.dialogBehaviourSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog({
    operators,
    startTime,
    stoppedAt,
    currentOption,
    currentCseData,
    showConfinedEntryForm = true,
  }) {
    const dialogRef = this.dialog.open(StartStopConfirmationComponent, {
      data: {
        operators,
        startTime,
        stoppedAt,
        currentOption,
        currentCseData,
        showConfinedEntryForm,
      },
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
