import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Bay, Card, Shift } from '../schedule.model';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from '../schedule.service';
import { AuthService } from '../../../core/auth/auth.service';
import { utc, unix } from 'moment';

@Component({
  selector: 'app-manual-schedule-overlay',
  templateUrl: './manual-schedule-overlay.component.html',
  styleUrls: ['./manual-schedule-overlay.component.scss'],
})
export class ManualScheduleOverlayComponent implements OnInit {
  manualRescheduleForm: UntypedFormGroup;
  isLoading = false;
  bays: Bay[];
  card: Card;
  shifts: Shift[];
  yesterdayTimestamp: number;
  lastAgendaDayTimestamp: number;
  hasManagerRole: boolean;

  CONFLICT_STATUS_CODE = 409;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { card: Card; bays: Bay[]; shifts: Shift[] },
    public dialogRef: MatDialogRef<ManualScheduleOverlayComponent>,
    private scheduleService: ScheduleService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.card = this.data.card;
    this.bays = this.data.bays;
    this.shifts = this.data.shifts;
    this.yesterdayTimestamp = utc().startOf('day').add(-1, 'day').unix();
    const lastShift = this.shifts.slice(-1)[0];
    this.lastAgendaDayTimestamp = unix(lastShift.finishTime)
      .utc()
      .endOf('day')
      .unix();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.hasManagerRole = this.authService.hasManagerRole();
  }

  get getBayOptions(): Bay[] {
    return this.manualRescheduleForm.controls.showIncompatibleBays.value
      ? this.bays
      : this.bays.filter((bay) => this.card.compatibleBays.includes(bay.id));
  }

  private initializeForm() {
    this.manualRescheduleForm = new UntypedFormGroup({
      showIncompatibleBays: new UntypedFormControl(false),
      selectedBay: new UntypedFormControl(this.card.bayId, Validators.required),
      startTime: new UntypedFormControl(
        this.card.startTime,
        Validators.required
      ),
    });

    this.manualRescheduleForm.controls.showIncompatibleBays.valueChanges.subscribe(
      (value) => {
        if (!value) {
          this.manualRescheduleForm.controls.selectedBay.setValue(
            this.card.bayId
          );
        }
      }
    );
  }

  private isFinishTimeInvalid() {
    const finishTime =
      this.manualRescheduleForm.controls.startTime.value +
      this.card.duration * 60;
    const lastShift = this.shifts.slice(-1)[0];
    const lastFinishTime = unix(lastShift.finishTime)
      .utc()
      .startOf('day')
      .add(1, 'day')
      .unix();
    if (finishTime > lastFinishTime) {
      return true;
    }
    return false;
  }

  submit() {
    this.isLoading = true;
    if (this.isFinishTimeInvalid()) {
      this.toastr.error('Scheduled finish time is invalid');
      this.isLoading = false;
      return;
    }

    if (this.manualRescheduleForm.valid) {
      this.scheduleService
        .manualReschedule({
          terminalId: this.authService.user.currentTerminal.id,
          requestId: this.card.id,
          startTime: this.manualRescheduleForm.controls.startTime.value,
          bayId: this.manualRescheduleForm.controls.selectedBay.value,
        })
        .then(() => {
          this.dialogRef.close(this.manualRescheduleForm.value);
          this.toastr.success('Request rescheduled successfully');
        })
        .catch((error) => {
          if (error.response.status === this.CONFLICT_STATUS_CODE) {
            this.toastr.warning(
              'There is no room for the desired spot.',
              'Conflict'
            );
          } else {
            this.toastr.error(
              'Cannot reschedule request',
              'Error scheduling request for this date and bay.'
            );
          }
          this.isLoading = false;
        });
    }
  }

  closeOverlay() {
    this.dialogRef.close();
  }
}
