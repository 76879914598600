import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ConfinedSpaceEntryDataUtils } from './confined-space-entry.data.utils';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { Operator } from 'src/app/dashboard/schedule/schedule.model';

@Component({
  selector: 'app-confined-space-entry',
  templateUrl: './confined-space-entry.component.html',
  styleUrls: ['./confined-space-entry.component.scss'],
})
export class ConfinedSpaceEntryComponent implements OnInit {
  formGroup: FormGroup;
  confinedEntry: FormControl;
  confinedEntryOperatorName: FormControl;
  confinedEntryType: FormControl;
  userTypedOperatorName: boolean = false;
  lastSelectedOperatorId: string;
  isDisabled: boolean = false;
  formFieldsEnum = {
    confinedEntry: 'Executed',
    confinedEntryType: 'Type',
    confinedEntryOperatorName: 'Work Performed By',
  };

  operators$: Observable<any>;
  uploadedFiles: Array<any> = [];
  operators: Array<Operator>;
  filteredOperators: any;
  workOrderStatus: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfinedSpaceEntryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.operators = this.data.operators;
    this.workOrderStatus = this.data.workOrderStatus;
  }

  ngOnInit(): void {
    this.createFormGroup();

    ConfinedSpaceEntryDataUtils.handleOperatorsInputList(this);
    ConfinedSpaceEntryDataUtils.handleConfinedEntryFormFields(this);
    ConfinedSpaceEntryDataUtils.handledConfinedEntryInitialData(this);
    this.handleWorkOrderCompleted();
  }

  updateOperatorName(
    event: MatOptionSelectionChange,
    operatorName: string,
    operatorId: string
  ) {
    ConfinedSpaceEntryDataUtils.updateOperatorName(
      this,
      event,
      operatorName,
      operatorId
    );
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group(
      ConfinedSpaceEntryDataUtils.createCseFormControls(this)
    );
  }

  cancel() {
    return this.dialogRef.close();
  }

  save() {
    const cseData = ConfinedSpaceEntryDataUtils.compileCseDataToSave(this);
    this.dialogRef.close(cseData);
  }

  isWorkOrderCompleted() {
    return this.workOrderStatus === 'Completed';
  }

  handleWorkOrderCompleted() {
    if (this.isWorkOrderCompleted()) {
      this.confinedEntryOperatorName.disable();
    }
  }
}
