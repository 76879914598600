import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  Subscription,
  Observable,
  of,
  Subject,
  interval,
  merge,
  combineLatest,
} from 'rxjs';
import {
  debounce,
  debounceTime,
  skip,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import {
  BusinessPartner,
  ServiceType,
  WashBillToTerminal,
  WashRequest,
  ProductContent,
  CompartmentServiceType,
  WashRequestStatus,
  SimplifiedServicePlan,
  ServicePlan,
  ReasonOfChangeOption,
} from '../wash-list.model';
import { Warehouse } from '../../../core/model/warehouse.model';
import { WashListService } from '../wash-list.service';
import { WashUpdateFieldsEnum, IFieldsState } from './wash-edit-fields.enum';
import { WorkOrderCreatedEditableFields } from './work-order-created-editable-fields.enum';
import { ServicePlanRelatedFields } from '../shared/service-plan-related-fields.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { compartmentsValidator } from '../shared/compartments-validator.directive';
import { WashItemService } from '../wash-item/wash-item.service';
import { DialogConfirmationService } from 'src/app/shared/dialog-confirmation/dialog-confirmation.service';
import { TankElasticSearch } from 'src/app/core/model/search-definition.model';
import { RequestWarningMessages } from '../wash-list.model';
import { ServiceTypeKeys } from '../wash-create/wash-create.model';
import { ServicePlanPopUpOverlayComponent } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up-overlay.component';
import { reasonOfChangeList } from '../wash-create/wash-create.model';
import {
  StandardServicePlanDuration,
  StandardServicePlanNames,
} from '../wash-item/wash-item.model';
import { ServicePlanPopUpInputModel } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.model';
import {
  popUpLabels,
  popUpHeaders,
  popUpDisclaimers,
} from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.constants';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { TimeService } from 'src/app/core/time.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { DateTimeService } from 'src/app/shared/date-time.service';

type ReducedWarehouse = Pick<Warehouse, 'id' | 'searchKey'>;

@Component({
  selector: 'app-wash-edit',
  templateUrl: './wash-edit.component.html',
  styleUrls: ['./wash-edit.component.scss'],
})
export class WashEditComponent implements OnInit {
  compartmentsQty$: Subscription;
  serviceTypeOntraxId$: Subscription;
  showContainersFromAllBusinessPartners$: Subscription;
  businessPartnerId$: Subscription;
  isVisible: boolean;
  washRevisionItems: any;
  actionDisclaimer: string;
  canSubmitRequest: boolean;
  isLoadingServicePlan = false;
  containerPlaceholder: string;

  // Tank type ahead subscription
  tankInput$ = new Subject<string | null>();
  tankInputSub$: Subscription;

  // Select options
  businessPartnerOptions$: Observable<BusinessPartner[]>;
  warehouseOptions$: Observable<ReducedWarehouse[]>;
  serviceTypeOptions$: Observable<ServiceType[]>;
  washBillToTerminalOptions$: Observable<WashBillToTerminal[]>;
  productContent$: Observable<ProductContent[]>;
  reasonOfChangeOptions$: Observable<ReasonOfChangeOption[]>;
  compartmentServiceTypeOptions = [
    CompartmentServiceType.SameAsMain,
    CompartmentServiceType.DoNotClean,
    CompartmentServiceType.Clean,
    CompartmentServiceType.CleanAndPrep,
    CompartmentServiceType.Prep,
  ];

  // Form components
  editForm: UntypedFormGroup;
  customerId: UntypedFormControl;
  phoneNumber: UntypedFormControl;
  container: UntypedFormControl;
  showContainersFromAllBusinessPartners: UntypedFormControl;
  warehouseId: UntypedFormControl;
  arrivalTime: UntypedFormControl;
  arrivalDate: UntypedFormControl;
  needByTime: UntypedFormControl;
  needByDate: UntypedFormControl;
  trailerArrivalDate: UntypedFormControl;
  trailerArrivalTime: UntypedFormControl;
  serviceTypeId: UntypedFormControl;
  serviceTypeOntraxId: UntypedFormControl;
  exteriorWash: UntypedFormControl;
  foodGrade: UntypedFormControl;
  washType: UntypedFormControl;
  recommendedWashType: UntypedFormControl;
  wasWashTypeChanged: UntypedFormControl;
  reasonOfChange: UntypedFormControl;
  kosher: UntypedFormControl;
  specialInstructions: UntypedFormControl;
  compartmentsQty: UntypedFormControl;
  lastContainedProductComp1Id: UntypedFormControl;
  lastContainedProductComp2Id: UntypedFormControl;
  lastContainedProductComp3Id: UntypedFormControl;
  lastContainedProductComp4Id: UntypedFormControl;
  lastContainedProductComp5Id: UntypedFormControl;
  lastContainedProductComp1Name: UntypedFormControl;
  lastContainedProductComp2Name: UntypedFormControl;
  lastContainedProductComp3Name: UntypedFormControl;
  lastContainedProductComp4Name: UntypedFormControl;
  lastContainedProductComp5Name: UntypedFormControl;
  containsNitrogenComp1: UntypedFormControl;
  containsNitrogenComp2: UntypedFormControl;
  containsNitrogenComp3: UntypedFormControl;
  containsNitrogenComp4: UntypedFormControl;
  containsNitrogenComp5: UntypedFormControl;
  serviceTypeComp1: UntypedFormControl;
  serviceTypeComp2: UntypedFormControl;
  serviceTypeComp3: UntypedFormControl;
  serviceTypeComp4: UntypedFormControl;
  serviceTypeComp5: UntypedFormControl;
  specialInstructionsComp1: UntypedFormControl;
  specialInstructionsComp2: UntypedFormControl;
  specialInstructionsComp3: UntypedFormControl;
  specialInstructionsComp4: UntypedFormControl;
  specialInstructionsComp5: UntypedFormControl;
  lastContainedProduct1Id: UntypedFormControl;
  lastContainedProduct2Id: UntypedFormControl;
  lastContainedProduct3Id: UntypedFormControl;
  lastContainedProduct1Name: UntypedFormControl;
  lastContainedProduct2Name: UntypedFormControl;
  lastContainedProduct3Name: UntypedFormControl;
  nitrogen: UntypedFormControl;
  tractorNumber: UntypedFormControl;
  pumpQty: UntypedFormControl;
  hosesQty: UntypedFormControl;
  fittingsQty: UntypedFormControl;
  ladingBill: UntypedFormControl;
  poNumber: UntypedFormControl;
  poNumberForPrep: UntypedFormControl;
  washBillToTerminalId: UntypedFormControl;

  containerOwnerId: string;
  containerOwnerName: string;
  containerTypeId: string;
  containerTypeName: string;
  containerId: string;
  containerNumber: string;
  invalidFields: Array<string> = [];
  validationMessage: string;
  displayValidationMessage = false;
  showPoNumberForPrep: boolean;
  isProcessing: boolean;
  pumpHoses: Array<number> = new Array(20).fill(1).map((x, i) => i + 1);
  hosesQtyOptions: Array<number> = new Array(10).fill(1).map((x, i) => i + 1);
  poNumberTip: string;
  poNumberPlaceholder: string;
  tankList: Array<any>;
  servicePlanObject: any;
  servicePlanObjectOnError: {
    servicePlan: ServicePlan;
    recommendedServicePlan: ServicePlan;
    standardServicePlan: ServicePlan;
  };
  recommendedServicePlanData: any;
  servicePlanList: Array<any>;
  servicePlanArray: Array<any>;
  simplifiedServicePlan: SimplifiedServicePlan;
  tankSearchTotalResults: number;
  tankSearchDisplayingResults: number;
  showSearchResultsHelperText: boolean;
  obServiceTypeId: string;
  flareRequired: boolean;
  lastContainedEmptyNoWash = 'Empty - No Wash';
  commonProductToastr: any;
  terminalInitialChanges: boolean = true;
  contentInitialChanges: boolean = true;
  arrivalTimestamp: number;
  needByTimestamp: number;
  trailerArrivalTimestamp: number;
  dateTimeValidators: any[] = [Validators.required];
  // Attachments
  uploadedFiles: Array<any> = [];
  arrivalTimeDisabled = false;

  fieldStatus: IFieldsState = {
    foodGrade: {
      disabled: false,
    },
  };

  get compartmentsQtyValue(): number {
    return this.compartmentsQty.value || 1;
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  get hasDispatcherRole() {
    return this.authService.hasDispatcherRole();
  }

  get isFoodGrade() {
    return this.foodGrade.value;
  }

  get showHeatFields() {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === this.serviceTypeOntraxId.value
    );

    if (!serviceType) {
      return false;
    }

    this.obServiceTypeId = serviceType.obId;
    return serviceType.key === ServiceTypeKeys.SteamHeat;
  }

  @Input() item: WashRequest;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private washListService: WashListService,
    private warehouseService: WarehouseService,
    private toastr: ToastrService,
    private washItemService: WashItemService,
    private dialogConfirmationService: DialogConfirmationService,
    public dialog: MatDialog,
    private businessPartnerService: BusinessPartnerService,
    private timeService: TimeService,
    private dateTimeService: DateTimeService
  ) {}

  ngOnInit() {
    this.tankInputSub$ = this.tankInput$
      .pipe(debounceTime(500))
      .subscribe((newTerm) => {
        if (!newTerm) {
          this.tankList = [];
          return;
        }

        this.updateTankList(newTerm);
      });

    this.reasonOfChangeOptions$ = this.washListService.reasonOfChangeOptions;

    this.createFormControls();
    this.createFormGroup();
    this.createSelectOptions();
    this.configureFieldsAsWorkOrderCreated();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    this.loadData();
    const canGetServicePlanData = this.canChangeServicePlan();

    if (canGetServicePlanData) {
      this.getServicePlanData(false, this.item).then(() => {
        this.callGetServicePlanDataOnChanges();
      });
    } else {
      this.callGetServicePlanDataOnChanges();
    }

    if (!this.customerId.value) {
      this.disableContainerField();
    }

    this.washType.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((servicePlan) => {
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Hose Clean',
          this.hosesQty
        );
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Pump Clean',
          this.pumpQty
        );
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Fitting Clean',
          this.fittingsQty
        );
      });
  }

  updateTankList(tankNumber?: string) {
    this.washListService
      .searchTanks(
        new TankElasticSearch(
          0,
          tankNumber,
          this.customerId.value,
          this.showContainersFromAllBusinessPartners.value
        )
      )
      .then((data) => {
        this.tankList = data.list;
        this.tankSearchDisplayingResults = data.list.length;
        this.tankSearchTotalResults = data.total;
      });
  }

  isStatusBeforeAccept() {
    return (
      this.item.status === WashRequestStatus.Draft ||
      this.item.status === WashRequestStatus.Created ||
      this.item.status === WashRequestStatus.Submitted ||
      this.item.status === WashRequestStatus.CreditHold
    );
  }

  isStatusBeforeSubmit() {
    return (
      this.item.status === WashRequestStatus.Draft ||
      this.item.status === WashRequestStatus.Created ||
      this.item.status === WashRequestStatus.CreditHold
    );
  }

  canChangeServicePlan() {
    return (
      (this.item.foodGrade || this.hasQualaRole) &&
      this.isStatusBeforeAccept() &&
      !this.item.workOrderCreated
    );
  }

  disableContainerField() {
    this.container.setValue(null);
    this.container.disable();
    this.containerPlaceholder =
      'This field will be disabled until a company has been selected.';
  }

  createFormControls() {
    this.customerId = new UntypedFormControl('', Validators.required);
    this.warehouseId = new UntypedFormControl('', Validators.required);
    this.phoneNumber = new UntypedFormControl('');
    this.arrivalTime = new UntypedFormControl('', this.dateTimeValidators);
    this.arrivalDate = new UntypedFormControl('', this.dateTimeValidators);
    this.needByDate = new UntypedFormControl('', this.dateTimeValidators);
    this.trailerArrivalDate = new UntypedFormControl('');
    this.trailerArrivalTime = new UntypedFormControl('');
    this.needByTime = new UntypedFormControl('', this.dateTimeValidators);
    this.container = new UntypedFormControl('', Validators.required);
    this.showContainersFromAllBusinessPartners = new UntypedFormControl(false);
    this.serviceTypeId = new UntypedFormControl('');
    this.serviceTypeOntraxId = new UntypedFormControl('', Validators.required);
    this.exteriorWash = new UntypedFormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.foodGrade = new UntypedFormControl({ value: '', disabled: true });
    this.recommendedWashType = new UntypedFormControl({
      value: '',
      disabled: true,
    });
    this.wasWashTypeChanged = new UntypedFormControl(false);
    this.washType = new UntypedFormControl({ value: '' });
    this.reasonOfChange = new UntypedFormControl('');
    this.kosher = new UntypedFormControl('');
    this.specialInstructions = new UntypedFormControl('');
    this.compartmentsQty = new UntypedFormControl({
      value: '',
      disabled: true,
    });
    this.lastContainedProductComp1Id = new UntypedFormControl('');
    this.lastContainedProductComp2Id = new UntypedFormControl('');
    this.lastContainedProductComp3Id = new UntypedFormControl('');
    this.lastContainedProductComp4Id = new UntypedFormControl('');
    this.lastContainedProductComp5Id = new UntypedFormControl('');
    this.lastContainedProductComp1Name = new UntypedFormControl('');
    this.lastContainedProductComp2Name = new UntypedFormControl('');
    this.lastContainedProductComp3Name = new UntypedFormControl('');
    this.lastContainedProductComp4Name = new UntypedFormControl('');
    this.lastContainedProductComp5Name = new UntypedFormControl('');
    this.containsNitrogenComp1 = new UntypedFormControl('');
    this.containsNitrogenComp2 = new UntypedFormControl('');
    this.containsNitrogenComp3 = new UntypedFormControl('');
    this.containsNitrogenComp4 = new UntypedFormControl('');
    this.containsNitrogenComp5 = new UntypedFormControl('');
    this.serviceTypeComp1 = new UntypedFormControl('');
    this.serviceTypeComp2 = new UntypedFormControl('');
    this.serviceTypeComp3 = new UntypedFormControl('');
    this.serviceTypeComp4 = new UntypedFormControl('');
    this.serviceTypeComp5 = new UntypedFormControl('');
    this.specialInstructionsComp1 = new UntypedFormControl('');
    this.specialInstructionsComp2 = new UntypedFormControl('');
    this.specialInstructionsComp3 = new UntypedFormControl('');
    this.specialInstructionsComp4 = new UntypedFormControl('');
    this.specialInstructionsComp5 = new UntypedFormControl('');
    this.lastContainedProduct1Id = new UntypedFormControl('');
    this.lastContainedProduct2Id = new UntypedFormControl('');
    this.lastContainedProduct3Id = new UntypedFormControl('');
    this.lastContainedProduct1Name = new UntypedFormControl('');
    this.lastContainedProduct2Name = new UntypedFormControl('');
    this.lastContainedProduct3Name = new UntypedFormControl('');
    this.nitrogen = new UntypedFormControl('');
    this.tractorNumber = new UntypedFormControl('');
    this.pumpQty = new UntypedFormControl('');
    this.hosesQty = new UntypedFormControl('');
    this.fittingsQty = new UntypedFormControl('');
    this.ladingBill = new UntypedFormControl('');
    this.poNumber = new UntypedFormControl('', Validators.required);
    this.poNumberForPrep = new UntypedFormControl('');
    this.washBillToTerminalId = new UntypedFormControl('', Validators.required);

    if (this.authService.hasDispatcherRole()) {
      this.checkCommonProducts();
    }

    if (this.isStatusBeforeAccept() && !this.item.washRequestIdOpenBravo) {
      this.subscribeToChanges();
    } else {
      this.warehouseId.disable();
      this.customerId.disable();
      this.showContainersFromAllBusinessPartners.disable();
      this.container.disable();
      this.serviceTypeOntraxId.disable();

      this.lastContainedProduct1Id.disable();

      this.lastContainedProductComp1Id.disable();
      this.lastContainedProductComp2Id.disable();
      this.lastContainedProductComp3Id.disable();
      this.lastContainedProductComp4Id.disable();
      this.lastContainedProductComp5Id.disable();
      this.disableFoodGrade();
      this.exteriorWash.disable();

      if (!this.isStatusBeforeAccept()) {
        this.lastContainedProduct2Id.disable();
        this.lastContainedProduct3Id.disable();
        this.washType.disable();
        this.wasWashTypeChanged.disable();
        this.reasonOfChange.disable();
      }
    }
  }

  callGetServicePlanDataOnChanges() {
    const searchServicePlan = merge(
      this.customerId.valueChanges,
      this.serviceTypeOntraxId.valueChanges,
      this.container.valueChanges,
      this.exteriorWash.valueChanges,
      this.foodGrade.valueChanges,
      this.lastContainedProduct1Id.valueChanges,
      this.lastContainedProduct2Id.valueChanges,
      this.lastContainedProduct3Id.valueChanges,
      this.lastContainedProductComp1Id.valueChanges,
      this.lastContainedProductComp2Id.valueChanges,
      this.lastContainedProductComp3Id.valueChanges,
      this.lastContainedProductComp4Id.valueChanges,
      this.lastContainedProductComp5Id.valueChanges
    );
    searchServicePlan.pipe(debounce(() => interval(500))).subscribe((_) => {
      if (!this.item.workOrderCreated) {
        this.washType.setValue(null);
        this.recommendedWashType.setValue(null);
        this.wasWashTypeChanged.setValue(false);
        this.wasWashTypeChanged.enable();
        this.reasonOfChange.setValue(null);
        this.getServicePlanData(true);
        if (
          this.recommendedServicePlanData &&
          this.recommendedServicePlanData.servicePlanID &&
          this.servicePlanObject &&
          this.servicePlanObject.servicePlanID
        ) {
          const checkIfServicePlanWasChanged =
            this.checkIfServicePlanWasChanged(
              this.servicePlanObject.servicePlanID,
              this.recommendedServicePlanData.servicePlanID
            );
          if (checkIfServicePlanWasChanged) {
            this.wasWashTypeChanged.setValue(true);
            this.washType.setValue({
              durationMinutes: this.item.servicePlan.servicePlanDurationMinutes,
              planID: this.item.servicePlan.servicePlanID,
              planName: this.item.servicePlan.servicePlanName,
            });
          }
        }
      }
    });
  }

  disableFoodGrade() {
    this.foodGrade.disable();
    this.fieldStatus.foodGrade.disabled = true;
  }

  enableFoodGrade() {
    this.foodGrade.enable();
    this.fieldStatus.foodGrade.disabled = false;
  }

  setRequiredFields() {
    if (this.isFoodGrade) {
      this.editForm.controls.lastContainedProduct1Id.setValidators([
        Validators.required,
      ]);
      this.editForm.controls.lastContainedProduct1Id.updateValueAndValidity();

      for (let index = 1; index <= this.compartmentsQtyValue; index++) {
        this.editForm.controls[
          `lastContainedProductComp${index}Id`
        ].clearValidators();
        this.editForm.controls[
          `lastContainedProductComp${index}Id`
        ].updateValueAndValidity();
      }
    } else {
      this.editForm.controls.lastContainedProduct1Id.clearValidators();
      this.editForm.controls.lastContainedProduct1Id.updateValueAndValidity();

      for (let index = 1; index <= this.compartmentsQtyValue; index++) {
        this.editForm.controls[
          `lastContainedProductComp${index}Id`
        ].setValidators([Validators.required]);
        this.editForm.controls[
          `lastContainedProductComp${index}Id`
        ].updateValueAndValidity();
      }
    }
  }

  subscribeToChanges() {
    this.businessPartnerId$ = this.customerId.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.container.enable();
          this.containerPlaceholder = '';

          if (!this.showContainersFromAllBusinessPartners.value) {
            this.editForm.controls.container.setValue(null);
            this.setContainerControlValidity();
          }

          const defaultExteriorWashValue =
            this.washListService.hasExteriorWashByDefault(value);

          this.exteriorWash.setValue(defaultExteriorWashValue);

          this.washListService.loadWashBillToTerminal(value);
          // After story QUAL-1921 adjust this settings
          const businessPartner =
            this.businessPartnerService.dataStore.businessPartners.find(
              (item) => item.id === value
            );
          this.poNumberPlaceholder =
            businessPartner && businessPartner.contmngPOFormathelp;
          this.poNumberTip =
            businessPartner &&
            businessPartner.contmngPOFormathelp &&
            `${businessPartner.contmngPOFormathelp}. If the customer will provide the PO number later, enter the word 'pending'.`;
        } else {
          this.editForm.controls.container.setValue(null);
          this.containerPlaceholder =
            'This field will be disabled until a company has been selected.';
          this.setContainerControlValidity();
          this.container.disable();
        }
      }
    );

    this.showContainersFromAllBusinessPartners$ =
      this.showContainersFromAllBusinessPartners.valueChanges.subscribe(
        (value) => {
          if (!value) {
            this.tankSearchDisplayingResults = null;
            this.editForm.controls.container.setValue(null);
            this.setContainerControlValidity();
          } else {
            if (this.containerNumber && this.customerId.value) {
              this.updateTankList(this.containerNumber);
            }
          }
        }
      );

    this.compartmentsQty$ = this.compartmentsQty.valueChanges.subscribe((_) => {
      this.cleanHiddenFields();
    });
    this.serviceTypeOntraxId$ = this.serviceTypeOntraxId.valueChanges.subscribe(
      (serviceTypeOnTraxId) => {
        this.serviceTypeHandler(serviceTypeOnTraxId);
      }
    );
    this.foodGrade.valueChanges.subscribe((value) => {
      this.foodGradeChangesHandler(value);
      this.setRequiredFields();
    });
    this.container.valueChanges.subscribe((selectedTank) => {
      if (selectedTank) {
        this.containerOwnerId = selectedTank.businessPartnerId;
        this.containerOwnerName = selectedTank.businessPartnerName;
        this.containerTypeId = selectedTank.containerTypeId;
        this.containerTypeName = selectedTank.containerTypeName;
        this.containerId = selectedTank.id;
        this.containerNumber = selectedTank.searchKey;
        this.compartmentsQty.setValue(+selectedTank.compartments);

        const canBeFoodGrade =
          this.washListService.dataStore.containerTypes.some(
            (item) =>
              item.name === selectedTank.containerTypeName &&
              item.canBeFoodGrade
          );

        const isFoodGradeOnly = this.washListService.isFoodGradeOnly(
          selectedTank.containerTypeName,
          canBeFoodGrade
        );

        if (isFoodGradeOnly) {
          this.foodGrade.setValue(true);
          this.compartmentsQty.setValue(1);
          this.disableFoodGrade();
        } else if (canBeFoodGrade) {
          this.enableFoodGrade();
        } else {
          this.foodGrade.setValue(false);
          this.disableFoodGrade();
        }
        this.handleExteriorWashToggle(selectedTank);
      } else {
        this.containerOwnerId = null;
        this.containerOwnerName = null;
        this.containerTypeId = null;
        this.containerTypeName = null;
        this.containerId = null;
        this.containerNumber = null;
        this.compartmentsQty.setValue(null);
        this.foodGrade.setValue(false);
        this.disableFoodGrade();
        this.exteriorWash.enable();
      }

      [
        { control: this.serviceTypeComp1, controlName: 'serviceTypeComp1' },
        { control: this.serviceTypeComp2, controlName: 'serviceTypeComp2' },
        { control: this.serviceTypeComp3, controlName: 'serviceTypeComp3' },
        { control: this.serviceTypeComp4, controlName: 'serviceTypeComp4' },
        { control: this.serviceTypeComp5, controlName: 'serviceTypeComp5' },
      ].forEach(({ control, controlName }) =>
        control.valueChanges.subscribe((value) =>
          this.handlerServiceTypeCompChanges(controlName, value)
        )
      );
    });

    combineLatest([
      this.container.valueChanges,
      this.customerId.valueChanges,
    ]).subscribe(([selectedTank, customerId]) => {
      const lookupTime = 90;
      const queryStartRange = this.timeService.getDateAsUTC(lookupTime);
      if (selectedTank) {
        const customer =
          this.businessPartnerService.dataStore.businessPartners.find(
            (item) => item.id === customerId
          );
        if (
          !customer ||
          !selectedTank ||
          selectedTank.id === this.item.containerId
        ) {
          return;
        }
        this.washListService
          .existWashRequest(
            selectedTank.searchKey,
            customer.name,
            queryStartRange
          )
          .then((result: any) => {
            if (result && result.length > 0) {
              this.toastr.warning(
                '',
                'There is a Request in progress for this tank!'
              );
            }
          });
      }
    });
  }

  private foodGradeChangesHandler(isFoodGrade: boolean) {
    if (isFoodGrade) {
      this.pumpQty.setValue(1);
      this.hosesQty.setValue(2);
      this.compartmentsQty.setValue(1); // Force value to 1
      this.washListService.toggleServiceTypeOptions(true);
    } else {
      const qty = this.container.value && +this.container.value.compartments;
      this.compartmentsQty.setValue(qty || 1); // Force value to 1
      this.washListService.toggleServiceTypeOptions(true);
    }
  }

  serviceTypeHandler(serviceTypeOnTraxId: string) {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === serviceTypeOnTraxId
    );
    this.obServiceTypeId = serviceType.obId;
    if (serviceType && serviceType.key === ServiceTypeKeys.CleanAndPrep) {
      this.showPoNumberForPrep = true;
      this.editForm.removeControl('steamHeatData');
      this.handleExteriorWashToggle(null);
    } else if (serviceType && serviceType.key === ServiceTypeKeys.SteamHeat) {
      this.showPoNumberForPrep = false;
      this.poNumberForPrep.setValue('');
      this.handleExteriorWashToggle(null);
    } else if (serviceType.key === ServiceTypeKeys.ExteriorWashOnly) {
      this.handleExteriorWashOnlyScenario();
    } else if (serviceType.key === ServiceTypeKeys.Prep) {
      this.handlePrepScenario();
    } else {
      this.showPoNumberForPrep = false;
      this.poNumberForPrep.setValue('');
      this.editForm.removeControl('steamHeatData');
      this.handleExteriorWashToggle(null);
    }
  }

  handlePrepScenario() {
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.name === this.lastContainedEmptyNoWash
      );
    this.lastContainedProductComp1Id.setValue(lastContainedProductComp1.id);
  }

  handleExteriorWashOnlyScenario() {
    this.exteriorWash.setValue(true);
    this.exteriorWash.disable();
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.name === this.lastContainedEmptyNoWash
      );
    this.lastContainedProductComp1Id.setValue(lastContainedProductComp1.id);
  }

  private async handlerServiceTypeCompChanges(
    controlName: string,
    value: string
  ) {
    const lastContainedControl = this.editForm.get(
      `${controlName.replace('serviceType', 'lastContainedProduct')}Id`
    );

    if (value === 'Do not clean' || this.isLoadingServicePlan) {
      const product = this.washListService.getProductContent('Empty');
      lastContainedControl.patchValue(product.id);
      lastContainedControl.disable();
    } else {
      lastContainedControl.enable();
    }
  }

  checkCommonProducts() {
    this.warehouseId.valueChanges
      .pipe(debounceTime(300), skip(1))
      .subscribe((value) => {
        if (value) {
          this.checkAllContentIds();
        }
      });

    const contentIdObservables = [
      this.lastContainedProductComp1Id.valueChanges,
      this.lastContainedProductComp2Id.valueChanges,
      this.lastContainedProductComp3Id.valueChanges,
      this.lastContainedProductComp4Id.valueChanges,
      this.lastContainedProductComp5Id.valueChanges,
    ];

    merge(...contentIdObservables)
      .pipe(debounceTime(300), skip(1))
      .subscribe((value) => {
        if (value && this.warehouseId.value) {
          this.checkAllContentIds();
        }
      });
  }

  private checkAllContentIds() {
    const allContentIds = [
      this.lastContainedProductComp1Id.value,
      this.lastContainedProductComp2Id.value,
      this.lastContainedProductComp3Id.value,
      this.lastContainedProductComp4Id.value,
      this.lastContainedProductComp5Id.value,
    ];

    const uniqueNonNullValues =
      this.washListService.uniqueNonNullProducts(allContentIds);

    if (uniqueNonNullValues.length > 0) {
      this.washListService
        .getCommonProduct(uniqueNonNullValues, this.warehouseId.value)
        .then((result) => {
          this.handleCommonProductResult(result);
        });
    }
  }

  private handleCommonProductResult(result: any) {
    const products: string[] = [];
    let matchNotFound = false;
    this.toastr.clear(this.commonProductToastr?.id);

    result.forEach((item) => {
      if (!item.findTerminalResult && item.contentName) {
        products.push(item.contentName);
        matchNotFound = true;
      }
    });

    if (matchNotFound) {
      const productList = this.washListService.formatProductList(products);
      this.commonProductToastr = this.toastr.warning(
        `The selected last contained product has not been serviced at this terminal in the last 12 months.
        Please contact the terminal office to confirm your appointment and any possible service restrictions.
        Product Name: ${productList} <br><b>Click here to close this message.</b>`,
        'Alert: Possible Delay or Cancellation',
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: true,
        }
      );
    }
  }

  createFormGroup() {
    this.editForm = new UntypedFormGroup({
      customerId: this.customerId,
      warehouseId: this.warehouseId,
      phoneNumber: this.phoneNumber,
      container: this.container,
      showContainersFromAllBusinessPartners:
        this.showContainersFromAllBusinessPartners,
      arrivalTime: this.arrivalTime,
      arrivalDate: this.arrivalDate,
      needByTime: this.needByTime,
      needByDate: this.needByDate,
      trailerArrivalDate: this.trailerArrivalDate,
      trailerArrivalTime: this.trailerArrivalTime,
      serviceTypeId: this.serviceTypeId,
      serviceTypeOntraxId: this.serviceTypeOntraxId,
      exteriorWash: this.exteriorWash,
      foodGrade: this.foodGrade,
      washType: this.washType,
      recommendedWashType: this.recommendedWashType,
      wasWashTypeChanged: this.wasWashTypeChanged,
      reasonOfChange: this.reasonOfChange,
      kosher: this.kosher,
      specialInstructions: this.specialInstructions,
      compartmentsQty: this.compartmentsQty,
      lastContainedProductComp1Id: this.lastContainedProductComp1Id,
      lastContainedProductComp2Id: this.lastContainedProductComp2Id,
      lastContainedProductComp3Id: this.lastContainedProductComp3Id,
      lastContainedProductComp4Id: this.lastContainedProductComp4Id,
      lastContainedProductComp5Id: this.lastContainedProductComp5Id,
      lastContainedProductComp1Name: this.lastContainedProductComp1Name,
      lastContainedProductComp2Name: this.lastContainedProductComp2Name,
      lastContainedProductComp3Name: this.lastContainedProductComp3Name,
      lastContainedProductComp4Name: this.lastContainedProductComp4Name,
      lastContainedProductComp5Name: this.lastContainedProductComp5Name,
      containsNitrogenComp1: this.containsNitrogenComp1,
      containsNitrogenComp2: this.containsNitrogenComp2,
      containsNitrogenComp3: this.containsNitrogenComp3,
      containsNitrogenComp4: this.containsNitrogenComp4,
      containsNitrogenComp5: this.containsNitrogenComp5,
      serviceTypeComp1: this.serviceTypeComp1,
      serviceTypeComp2: this.serviceTypeComp2,
      serviceTypeComp3: this.serviceTypeComp3,
      serviceTypeComp4: this.serviceTypeComp4,
      serviceTypeComp5: this.serviceTypeComp5,
      specialInstructionsComp1: this.specialInstructionsComp1,
      specialInstructionsComp2: this.specialInstructionsComp2,
      specialInstructionsComp3: this.specialInstructionsComp3,
      specialInstructionsComp4: this.specialInstructionsComp4,
      specialInstructionsComp5: this.specialInstructionsComp5,
      lastContainedProduct1Id: this.lastContainedProduct1Id,
      lastContainedProduct2Id: this.lastContainedProduct2Id,
      lastContainedProduct3Id: this.lastContainedProduct3Id,
      nitrogen: this.nitrogen,
      tractorNumber: this.tractorNumber,
      pumpQty: this.pumpQty,
      hosesQty: this.hosesQty,
      fittingsQty: this.fittingsQty,
      ladingBill: this.ladingBill,
      poNumber: this.poNumber,
      poNumberForPrep: this.poNumberForPrep,
      washBillToTerminalId: this.washBillToTerminalId,
    });
  }

  getValidationMessage(invalidFields) {
    if (invalidFields.length === 1) {
      return `Please enter a ${invalidFields[0]}`;
    }
    const firsts = invalidFields.slice(0, invalidFields.length - 1);
    const last = invalidFields[invalidFields.length - 1];
    return `Please enter a ${firsts.join(', ')} and ${last}`;
  }

  getEditFormValidationErrors() {
    const nowInSeconds = Math.round(Date.now() / 1000);
    const invalidFields = [];

    this.setContainerControlValidity();
    invalidFields.push(...this.getFormValidationErrors(this.editForm));

    if (
      this.trailerArrivalTimestamp &&
      this.trailerArrivalTimestamp > nowInSeconds
    ) {
      invalidFields.push(
        'valid time. The Actual Arrival time cannot be in the future.'
      );
    }

    if (this.actualArrivalDateTimeIsInvalid()) {
      invalidFields.push(
        'valid "Actual Arrival" (either set value for "Actual Arrival Date" and "Actual Arrival Time" or remove both)'
      );
    }

    if (this.needByTimestamp && this.arrivalTimestamp >= this.needByTimestamp) {
      invalidFields.push('Need by time after the Arrival time');
    }

    // Apply cross-fields validation on compartments
    const compartmentsValidationError: ValidationErrors =
      compartmentsValidator(this.editForm) || {};
    Object.values(compartmentsValidationError).forEach((value) => {
      invalidFields.push(WashUpdateFieldsEnum[value]);
    });

    return invalidFields;
  }

  getFormValidationErrors(formGroup: UntypedFormGroup): Array<any> {
    const invalidFields = [];
    Object.keys(formGroup.controls).forEach((key) => {
      if (formGroup.get(key) instanceof UntypedFormGroup) {
        const validationErrors = this.getFormValidationErrors(
          formGroup.get(key) as UntypedFormGroup
        );
        invalidFields.push(...validationErrors);
        return;
      }

      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          invalidFields.push(WashUpdateFieldsEnum[key]);
        });
      }
    });

    return invalidFields;
  }

  actualArrivalDateTimeIsInvalid() {
    if (this.trailerArrivalDate.value || this.trailerArrivalTime.value) {
      if (!this.trailerArrivalDate.value || !this.trailerArrivalTime.value) {
        return true;
      }
    }
    return false;
  }

  createSelectOptions() {
    if (this.authService.hasDispatcherRole()) {
      const { user } = this.authService;
      this.businessPartnerOptions$ = of([
        { id: user.businessPartnerId, name: user.businessPartnerName },
      ]);
      this.customerId.setValue(user.businessPartnerId);
      this.customerId.markAsTouched();
      this.customerId.disable();
      this.warehouseOptions$ = this.warehouseService.warehouses$;
    } else {
      this.businessPartnerOptions$ =
        this.businessPartnerService.businessPartners;
      this.warehouseId.setValue(this.authService.user.currentTerminal.id);
      this.warehouseId.markAsTouched();
      this.warehouseId.disable();
      this.warehouseOptions$ = of([
        {
          id: this.authService.user.currentTerminal.id,
          searchKey: this.authService.user.currentTerminal.key,
        },
      ]);
    }
    this.washBillToTerminalOptions$ =
      this.washListService.washBillToTerminals.pipe(
        map((data) =>
          data.filter(
            (item) => item.businessPartnerId === this.customerId.value
          )
        )
      );
    this.washBillToTerminalId.setValue(this.item.washBillToTerminalId);
    this.serviceTypeOptions$ = this.washListService.serviceTypes;
    this.productContent$ = this.washListService.productContents;
  }

  loadData() {
    let initialArrivalDate: Date;
    let initialArrivalTime: string = '';
    let initialNeedByDate: Date;
    let initialNeedByTime: string = '';
    let initialTrailerArrivalDate: Date;
    let initialTrailerArrivalTime: string = '';
    [initialArrivalDate, initialArrivalTime] =
      this.dateTimeService.splitDateAndTime(this.item.arrivalTime);

    [initialNeedByDate, initialNeedByTime] =
      this.dateTimeService.splitDateAndTime(this.item.needByTime);

    [initialTrailerArrivalDate, initialTrailerArrivalTime] = this.item
      .trailerArrivalTime
      ? this.dateTimeService.splitDateAndTime(this.item.trailerArrivalTime)
      : [null, null];

    const customer =
      this.businessPartnerService.dataStore.businessPartners.find(
        (item) => item.id === this.item.customerId
      );
    this.customerId.setValue(customer && customer.id);
    this.poNumberTip = customer && customer.contmngPOFormathelp;

    this.containerOwnerId = this.item.containerOwnerId;
    this.containerOwnerName = this.item.containerOwner;
    this.containerTypeId = this.item.containerTypeId;
    this.containerTypeName = this.item.containerType;
    this.containerId = this.item.containerId;
    this.flareRequired = this.item.flareRequired;
    this.containerNumber = this.item.containerNumber;
    const displayName = `${this.item.containerNumber} | ${this.item.containerType} | ${this.item.containerOwner}`;
    this.container.setValue({
      id: this.item.containerId,
      searchKey: this.item.containerNumber,
      businessPartnerId: this.item.containerOwnerId,
      businessPartnerName: this.item.containerOwner,
      containerTypeId: this.item.containerTypeId,
      containerTypeName: this.item.containerType,
      displayName,
    });

    // Get service type from data store
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === this.item.serviceTypeOntraxId
    );
    this.obServiceTypeId = serviceType.obId;
    this.serviceTypeOntraxId.setValue(serviceType && serviceType.id);
    this.warehouseId.setValue(this.item.terminalId);
    this.phoneNumber.setValue(this.item.phoneNumber);
    this.arrivalDate.setValue(initialArrivalDate);
    this.arrivalTime.setValue(initialArrivalTime);
    this.needByTime.setValue(this.item.needByTime);
    this.needByDate.setValue(initialNeedByDate);
    this.needByTime.setValue(initialNeedByTime);
    this.trailerArrivalDate.setValue(initialTrailerArrivalDate);
    this.trailerArrivalTime.setValue(initialTrailerArrivalTime);
    this.serviceTypeId.setValue(this.item.serviceTypeOntraxId);
    this.foodGrade.setValue(this.item.foodGrade);
    this.kosher.setValue(this.item.kosher);
    this.specialInstructions.setValue(this.item.specialInstructions);
    this.compartmentsQty.setValue(this.item.compartmentsQty);
    this.lastContainedProductComp1Id.setValue(
      this.item.lastContainedProductComp1Id
    );
    this.lastContainedProductComp2Id.setValue(
      this.item.lastContainedProductComp2Id
    );
    this.lastContainedProductComp3Id.setValue(
      this.item.lastContainedProductComp3Id
    );
    this.lastContainedProductComp4Id.setValue(
      this.item.lastContainedProductComp4Id
    );
    this.lastContainedProductComp5Id.setValue(
      this.item.lastContainedProductComp5Id
    );
    this.lastContainedProductComp1Name.setValue(
      this.item.lastContainedProductComp1Name
    );
    this.lastContainedProductComp2Name.setValue(
      this.item.lastContainedProductComp2Name
    );
    this.lastContainedProductComp3Name.setValue(
      this.item.lastContainedProductComp3Name
    );
    this.lastContainedProductComp4Name.setValue(
      this.item.lastContainedProductComp4Name
    );
    this.lastContainedProductComp5Name.setValue(
      this.item.lastContainedProductComp5Name
    );
    this.containsNitrogenComp1.setValue(this.item.containsNitrogenComp1);
    this.containsNitrogenComp2.setValue(this.item.containsNitrogenComp2);
    this.containsNitrogenComp3.setValue(this.item.containsNitrogenComp3);
    this.containsNitrogenComp4.setValue(this.item.containsNitrogenComp4);
    this.containsNitrogenComp5.setValue(this.item.containsNitrogenComp5);
    this.serviceTypeComp1.setValue(this.item.serviceTypeComp1);
    this.serviceTypeComp2.setValue(this.item.serviceTypeComp2);
    this.serviceTypeComp3.setValue(this.item.serviceTypeComp3);
    this.serviceTypeComp4.setValue(this.item.serviceTypeComp4);
    this.serviceTypeComp5.setValue(this.item.serviceTypeComp5);
    this.specialInstructionsComp1.setValue(this.item.specialInstructionsComp1);
    this.specialInstructionsComp2.setValue(this.item.specialInstructionsComp2);
    this.specialInstructionsComp3.setValue(this.item.specialInstructionsComp3);
    this.specialInstructionsComp4.setValue(this.item.specialInstructionsComp4);
    this.specialInstructionsComp5.setValue(this.item.specialInstructionsComp5);
    this.lastContainedProduct1Id.setValue(this.item.lastContainedProduct1Id);
    this.lastContainedProduct2Id.setValue(this.item.lastContainedProduct2Id);
    this.lastContainedProduct3Id.setValue(this.item.lastContainedProduct3Id);
    this.lastContainedProduct1Name.setValue(
      this.item.lastContainedProduct1Name
    );
    this.lastContainedProduct2Name.setValue(
      this.item.lastContainedProduct2Name
    );
    this.lastContainedProduct3Name.setValue(
      this.item.lastContainedProduct3Name
    );
    this.nitrogen.setValue(this.item.nitrogen);
    this.tractorNumber.setValue(this.item.tractorNumber);
    this.pumpQty.setValue(this.item.pumpQty);
    this.hosesQty.setValue(this.item.hosesQty);
    this.fittingsQty.setValue(this.item.fittingsQty);
    this.ladingBill.setValue(this.item.ladingBill);
    this.poNumber.setValue(this.item.poNumber);
    this.poNumberForPrep.setValue(this.item.poNumberForPrep);
    this.showPoNumberForPrep = this.item.serviceType === 'Clean and Prep';
    this.loadServicePlanData();
    this.exteriorWash.setValue(!!this.item.exteriorWash);

    // Get bill to terminal for customer
    this.washListService.loadWashBillToTerminal(this.item.customerId);
    if (this.item.files) {
      this.uploadedFiles = this.item.files;
    }
    this.getWashRevisionItems();
  }

  loadServicePlanData() {
    if (this.item.servicePlan) {
      this.washType.setValue({
        durationMinutes: this.item.servicePlan.servicePlanDurationMinutes,
        planID: this.item.servicePlan && this.item.servicePlan.servicePlanID,
        planName: this.item.servicePlan.servicePlanName,
      });
      if (
        (this.recommendedServicePlanData &&
          this.recommendedServicePlanData.servicePlanID) ||
        (this.item.recommendedServicePlan &&
          this.item.recommendedServicePlan.servicePlanID)
      ) {
        const checkIfServicePlanWasChanged = this.checkIfServicePlanWasChanged(
          this.item.servicePlan.servicePlanID,
          (this.item.recommendedServicePlan &&
            this.item.recommendedServicePlan.servicePlanID) ||
            this.recommendedServicePlanData.servicePlanID
        );
        this.wasWashTypeChanged.setValue(checkIfServicePlanWasChanged);
        this.recommendedWashType.setValue(
          (this.recommendedServicePlanData &&
            this.recommendedServicePlanData.servicePlanName) ||
            (this.item.recommendedServicePlan &&
              this.item.recommendedServicePlan.servicePlanName)
        );
      } else {
        this.wasWashTypeChanged.setValue(true);
      }
    }
    this.reasonOfChange.setValue(this.item.reasonOfChange || '');
  }
  cleanHiddenFields() {
    // LastContainedProductComps
    if (this.foodGrade.value) {
      this.cleanNoFoodCompartments(0);
    } else {
      this.cleanNoFoodCompartments(this.compartmentsQtyValue);
      this.lastContainedProduct1Id.setValue(undefined, { emitEvent: false });
      this.lastContainedProduct2Id.setValue(undefined, { emitEvent: false });
      this.lastContainedProduct3Id.setValue(undefined, { emitEvent: false });
    }
  }

  cleanNoFoodCompartments(visibleCompsQty: number) {
    for (let index = 1; index <= 5; index++) {
      if (index > visibleCompsQty) {
        this.editForm.controls[`lastContainedProductComp${index}Id`].setValue(
          undefined,
          { emitEvent: false }
        );
        this.editForm.controls[`containsNitrogenComp${index}`].setValue(
          undefined,
          { emitEvent: false }
        );
        this.editForm.controls[`serviceTypeComp${index}`].setValue(undefined, {
          emitEvent: false,
        });
        this.editForm.controls[`specialInstructionsComp${index}`].setValue(
          undefined,
          { emitEvent: false }
        );
      } else {
        let containsNitrogen =
          this.editForm.controls[`containsNitrogenComp${index}`].value;
        containsNitrogen =
          containsNitrogen !== undefined ? containsNitrogen : true;
        this.editForm.controls[`containsNitrogenComp${index}`].setValue(
          containsNitrogen,
          { emitEvent: false }
        );
        const serviceType =
          this.editForm.controls[`serviceTypeComp${index}`].value ||
          CompartmentServiceType.SameAsMain;
        this.editForm.controls[`serviceTypeComp${index}`].setValue(
          serviceType,
          { emitEvent: false }
        );
      }
    }
  }

  configureFieldsAsWorkOrderCreated() {
    // If a work Order was created for this request or its status is ACCEPTED,
    // all the fields will be read only.
    // except for the fields listed in the WorkOrderCreatedEditableFields enum
    if (
      this.item.workOrderCreated ||
      this.item.status === WashRequestStatus.Accepted
    ) {
      Object.keys(this.editForm.controls).forEach((key) => {
        if (!WorkOrderCreatedEditableFields[key]) {
          this.editForm.get(key).disable();
        }
      });
      if (this.authService.hasQualaWorkerRole()) {
        this.editForm.get('needByTime').enable();
        this.editForm.get('needByDate').enable();
        this.editForm.get('trailerArrivalTime').enable();
        this.editForm.get('trailerArrivalDate').enable();
      }
    }
  }

  changeFieldsStatus(fieldsToDisable: any, status: string) {
    Object.keys(this.editForm.controls).forEach((key) => {
      if (fieldsToDisable[key]) {
        if (status === 'enable') {
          if (this.fieldStatus[key] && this.fieldStatus[key].disabled) {
            this.editForm.get(key as string).disable({
              emitEvent: false,
            });
          } else {
            this.editForm.get(key as string).enable({
              emitEvent: false,
            });
          }
        } else if (status === 'disable') {
          this.editForm.get(key as string).disable({
            emitEvent: false,
          });
        }
      }
    });
  }

  setContainerControlValidity() {
    if (
      !this.editForm.controls.container.value ||
      !this.editForm.controls.container.value.businessPartnerId ||
      !this.editForm.controls.container.value.businessPartnerId.trim() ||
      !this.editForm.controls.container.value.businessPartnerName ||
      !this.editForm.controls.container.value.businessPartnerName.trim() ||
      !this.editForm.controls.container.value.containerTypeId ||
      !this.editForm.controls.container.value.containerTypeId.trim() ||
      !this.editForm.controls.container.value.containerTypeName ||
      !this.editForm.controls.container.value.containerTypeName.trim() ||
      !this.editForm.controls.container.value.id ||
      !this.editForm.controls.container.value.id.trim() ||
      !this.editForm.controls.container.value.searchKey ||
      !this.editForm.controls.container.value.searchKey.trim()
    ) {
      this.editForm.controls.container.setValue(null);
      this.tankList = [];
      /* tslint:disable:no-string-literal */
      document.querySelector('#container input')['value'] = '';
    }
  }

  setProductContentToWashRequest(washRequest: WashRequest) {
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp1Id
      );
    washRequest.lastContainedProductComp1Name =
      lastContainedProductComp1 && lastContainedProductComp1.name;
    const lastContainedProductComp2 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp2Id
      );
    washRequest.lastContainedProductComp2Name =
      lastContainedProductComp2 && lastContainedProductComp2.name;
    const lastContainedProductComp3 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp3Id
      );
    washRequest.lastContainedProductComp3Name =
      lastContainedProductComp3 && lastContainedProductComp3.name;
    const lastContainedProductComp4 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp4Id
      );
    washRequest.lastContainedProductComp4Name =
      lastContainedProductComp4 && lastContainedProductComp4.name;
    const lastContainedProductComp5 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp5Id
      );
    washRequest.lastContainedProductComp5Name =
      lastContainedProductComp5 && lastContainedProductComp5.name;

    const lastContainedProduct1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct1Id
      );
    washRequest.lastContainedProduct1Name =
      lastContainedProduct1 && lastContainedProduct1.name;
    const lastContainedProduct2 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct2Id
      );
    washRequest.lastContainedProduct2Name =
      lastContainedProduct2 && lastContainedProduct2.name;
    const lastContainedProduct3 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct3Id
      );
    washRequest.lastContainedProduct3Name =
      lastContainedProduct3 && lastContainedProduct3.name;

    return washRequest;
  }

  checkTrailerArrival() {
    const trailerArrivalDate = this.trailerArrivalDate.value;
    const trailerArrivalTime = this.trailerArrivalTime.value;
    if (!trailerArrivalTime || !trailerArrivalDate) {
      this.trailerArrivalTimestamp = null;
      return;
    }
    this.trailerArrivalTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        trailerArrivalDate,
        trailerArrivalTime
      );
  }

  // Actions
  async submit() {
    this.displayValidationMessage = false;
    this.isProcessing = true;
    const arrivalDate = this.arrivalDate.value;
    const arrivalTime = this.arrivalTime.value;
    const needByDate = this.needByDate.value;
    const needByTime = this.needByTime.value;

    this.arrivalTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        arrivalDate,
        arrivalTime
      );
    this.needByTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        needByDate,
        needByTime
      );
    this.checkTrailerArrival();

    const invalidFields = this.getEditFormValidationErrors();
    if (
      this.hasQualaRole &&
      this.wasWashTypeChanged.value &&
      !this.reasonOfChange.value &&
      this.isStatusBeforeAccept()
    ) {
      invalidFields.push('Reason of Change');
    }
    if (invalidFields.length > 0) {
      this.validationMessage = this.getValidationMessage(invalidFields);
      this.displayValidationMessage = true;
      this.isProcessing = false;
      return;
    }

    let editedWashRequest: WashRequest = new WashRequest(
      this.editForm.getRawValue()
    );

    // Timeframe
    editedWashRequest.arrivalTime = this.arrivalTimestamp;
    editedWashRequest.needByTime = this.needByTimestamp;
    editedWashRequest.trailerArrivalTime = this.trailerArrivalTimestamp;

    if (this.trailerArrivalTimestamp) {
      editedWashRequest.containerArrived = true;
    } else if (this.item.trailerArrivalDate) {
      editedWashRequest.containerArrived = false;
    }

    delete editedWashRequest.trailerArrivalDate;
    delete editedWashRequest.arrivalDate;
    delete editedWashRequest.needbyDate;

    // Customer
    const customer =
      this.businessPartnerService.dataStore.businessPartners.find(
        (item) => item.id === this.customerId.value
      );
    editedWashRequest.customerId = this.customerId.value;
    editedWashRequest.customer = customer && customer.name;
    // Container Owner
    editedWashRequest.containerOwner = this.containerOwnerName;
    editedWashRequest.containerOwnerId = this.containerOwnerId;
    // Container Number
    editedWashRequest.containerId = this.containerId;
    editedWashRequest.containerNumber = this.containerNumber;
    // Container Type
    editedWashRequest.containerType = this.containerTypeName;
    editedWashRequest.containerTypeId = this.containerTypeId;
    // Customer Comments
    const customerComments = customer && customer.contmngCustomernotesParsed;

    // Set value (names) extracted from id
    // Service Type
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === editedWashRequest.serviceTypeOntraxId
    );
    editedWashRequest.serviceType = serviceType && serviceType.displayName;
    editedWashRequest.serviceTypeOntraxId = serviceType && serviceType.id;
    editedWashRequest.serviceTypeId = serviceType && serviceType.obId;
    // Wash Bill To Terminal
    const washBillToTerminal =
      this.washListService.dataStore.billToTerminals.find(
        (item) => item.id === editedWashRequest.washBillToTerminalId
      );
    editedWashRequest.washBillToTerminal =
      washBillToTerminal && washBillToTerminal.name;

    // Warehouse
    editedWashRequest.terminal = this.washListService.getTerminalSearchKey(
      this.warehouseId.value
    );
    editedWashRequest.terminalId = this.warehouseId.value;

    editedWashRequest = this.setProductContentToWashRequest(editedWashRequest);

    // Set attachments
    editedWashRequest.files = this.uploadedFiles;

    // Set ID
    editedWashRequest.id = this.item.id;

    // Set flareRequired
    editedWashRequest.flareRequired = this.flareRequired;

    // Clear steam heat data if not heat service
    const isHeatRequest = serviceType.key === ServiceTypeKeys.SteamHeat;
    if (!isHeatRequest) {
      delete editedWashRequest.steam;
      delete editedWashRequest.steamCost;
      delete editedWashRequest.steamHeatData;
    }

    // Set service plan for Quala Employee
    if (this.hasQualaRole) {
      if (this.servicePlanObject) {
        editedWashRequest =
          this.setServicePlanDataToWashRequestQuala(editedWashRequest);
      } else if (this.servicePlanObjectOnError) {
        editedWashRequest =
          this.setServicePlanDataToWashRequestWhenError(editedWashRequest);
      }
    }

    // Set service plan for Dispatcher
    if (!this.hasQualaRole) {
      if (this.servicePlanObject) {
        editedWashRequest =
          this.setServicePlanDataToWashRequestDispatcher(editedWashRequest);
      } else if (this.simplifiedServicePlan) {
        editedWashRequest.servicePlan = this.buildServicePlanObject(
          this.simplifiedServicePlan
        );
      }
    }
    const hasReasonOfChangeChanged =
      this.editForm.controls.reasonOfChange.dirty;

    const hasServicePlanChanged =
      this.editForm.controls.washType.dirty || hasReasonOfChangeChanged;

    const poValidationResult = this.washItemService.applyPOValidation({
      businessPartner: customer,
      poNumber: editedWashRequest.poNumber,
      poNumberForPrep: editedWashRequest.poNumberForPrep,
    });

    if (this.item.reasonOfChange && !hasReasonOfChangeChanged) {
      editedWashRequest.reasonOfChange = this.item.reasonOfChange;
      editedWashRequest.reasonOfChangeCode = this.item.reasonOfChangeCode;
    }

    // If valid PO, create wash request
    if (poValidationResult.isValid) {
      if (this.hasQualaRole && hasServicePlanChanged) {
        this.displayServicePlanOverlay(
          popUpHeaders.edit,
          popUpLabels.edit,
          this.actionDisclaimer,
          editedWashRequest,
          this.canSubmitRequest,
          customerComments
        );
        return;
      }
      this.editWashRequest(editedWashRequest);
      return;
    }

    // Quala employees are not allowed to submit or accept invalid PO numbers
    // If invalid, warn or block the user before submitting
    const shouldBlockIfInvalidPO =
      this.hasQualaRole &&
      [
        WashRequestStatus.Accepted,
        WashRequestStatus.Submitted,
        WashRequestStatus.ReviewNeeded,
      ].includes(this.item.status);
    if (shouldBlockIfInvalidPO) {
      this.toastr.error(poValidationResult.message, 'Invalid PO Number');
      this.isProcessing = false;
      return;
    }

    // Otherwise, just show a confirmation dialog
    const result = await this.dialogConfirmationService.openDialog({
      text: `${poValidationResult.message}. Continue?`,
      icon: 'check',
      action: 'Yes',
      title: 'Invalid PO Number',
    });
    if (!result) {
      // User decided to fix PO number
      this.isProcessing = false;
      return;
    }

    // User ignored invalid PO and updated wash request
    if (this.hasQualaRole && hasServicePlanChanged) {
      this.displayServicePlanOverlay(
        popUpHeaders.edit,
        popUpLabels.edit,
        this.actionDisclaimer,
        editedWashRequest,
        this.canSubmitRequest,
        customerComments
      );
      return;
    }
    this.editWashRequest(editedWashRequest);
  }

  private editWashRequest(editedWashRequest: WashRequest) {
    this.washListService.update(editedWashRequest).then(
      (response: { warning?: string; washRequest: WashRequest }) => {
        this.cancel();
        const updatedWashRequest = new WashRequest(response.washRequest);
        if (response.washRequest.status === this.item.status) {
          this.washListService.updateWashRequestInDataStore(updatedWashRequest);
        } else {
          this.washListService.removeWashRequestFromDataStore(
            updatedWashRequest
          );
        }
        if (!response.warning) {
          this.toastr.success('', 'Request updated!');
          return;
        }

        if (
          response.warning.toUpperCase() ===
          RequestWarningMessages.STEAM_SDS_FILE_MISSING
        ) {
          this.toastr.warning(
            'Request has been received but it will not be processed until the SDS is received.',
            'Missing SDS file'
          );
        } else {
          if (this.authService.hasQualaWorkerRole) {
            this.toastr.warning(
              'Requests will not be scheduled. Customer needs to contact the sales representative.',
              'Account on hold.'
            );
          } else {
            this.toastr.warning(
              'Requests will not be scheduled. Please contact your sales representative.',
              'Account on hold.'
            );
          }
        }
      },
      (error) => {
        this.validationMessage = 'Error trying to create Wash Request';
        this.displayValidationMessage = false;
        this.toastr.error('', 'Error updating the request!');
        this.isProcessing = false;
        throw error;
      }
    );
  }

  cancel() {
    this.toastr.clear(this.commonProductToastr?.id);
    this.closed.emit();
  }

  private buildServicePlanObject(selectedServicePlan: SimplifiedServicePlan) {
    if (
      selectedServicePlan &&
      selectedServicePlan.planName &&
      selectedServicePlan.durationMinutes
    ) {
      return {
        servicePlanName: selectedServicePlan.planName,
        servicePlanDurationMinutes: selectedServicePlan.durationMinutes,
        servicePlanID: selectedServicePlan.planID,
      };
    }
  }

  private setServicePlanDataToWashRequestQuala(washRequest: WashRequest) {
    const selectedServicePlan = this.washType.value;
    delete this.servicePlanObject.servicePlanOptions;

    washRequest.recommendedServicePlan = this.recommendedServicePlanData;
    washRequest.wasServicePlanChanged = this.wasWashTypeChanged.value;
    washRequest.servicePlan = this.recommendedServicePlanData;

    if (this.wasWashTypeChanged.value) {
      const selectedServicePlanObject =
        this.buildServicePlanObject(selectedServicePlan);

      this.servicePlanObject.servicePlanDurationMinutes =
        selectedServicePlanObject.servicePlanDurationMinutes;
      this.servicePlanObject.servicePlanID =
        selectedServicePlanObject.servicePlanID;
      this.servicePlanObject.servicePlanName =
        selectedServicePlanObject.servicePlanName;
      washRequest.servicePlan = this.servicePlanObject;
      washRequest.reasonOfChange = this.reasonOfChange.value.displayName;
      washRequest.reasonOfChangeCode = this.reasonOfChange.value.code;
      return washRequest;
    }

    delete washRequest.reasonOfChange;
    return washRequest;
  }

  private setServicePlanDataToWashRequestDispatcher(
    washRequest: WashRequest
  ): WashRequest {
    const selectedServicePlan = this.washType.value;
    delete this.servicePlanObject.servicePlanOptions;

    const selectedServicePlanObject =
      this.buildServicePlanObject(selectedServicePlan);
    this.servicePlanObject.servicePlanDurationMinutes =
      selectedServicePlanObject.servicePlanDurationMinutes;
    this.servicePlanObject.servicePlanID =
      selectedServicePlanObject.servicePlanID;
    this.servicePlanObject.servicePlanName =
      selectedServicePlanObject.servicePlanName;

    washRequest.servicePlan = this.servicePlanObject;
    washRequest.recommendedServicePlan = this.recommendedServicePlanData;

    washRequest.wasServicePlanChanged = this.checkIfServicePlanWasChanged(
      washRequest.servicePlan.servicePlanID,
      washRequest.recommendedServicePlan.servicePlanID
    );
    if (washRequest.wasServicePlanChanged) {
      washRequest.reasonOfChange =
        reasonOfChangeList.createdByDispatcher.displayName;
      washRequest.reasonOfChangeCode =
        reasonOfChangeList.createdByDispatcher.code;
    }

    return washRequest;
  }

  private checkIfServicePlanWasChanged(
    actualServicePlanId: string,
    recommendedServicePlanId: string
  ): boolean {
    return actualServicePlanId !== recommendedServicePlanId;
  }

  private setServicePlanDataToWashRequestWhenError(
    washRequest: WashRequest
  ): WashRequest {
    washRequest.recommendedServicePlan = this.item.recommendedServicePlan;

    const wasToggleSelectedAsNo =
      !this.wasWashTypeChanged.value && this.item.recommendedServicePlan;
    if (wasToggleSelectedAsNo) {
      washRequest.servicePlan = this.item.recommendedServicePlan;
      washRequest.wasServicePlanChanged = false;
      delete washRequest.reasonOfChange;
      return washRequest;
    }

    const recommendedServicePlanName =
      this.servicePlanObjectOnError.recommendedServicePlan &&
      this.servicePlanObjectOnError.recommendedServicePlan.servicePlanName;
    const wasRecommendedServicePlanSelected =
      this.washType.value.planName === recommendedServicePlanName;
    if (wasRecommendedServicePlanSelected) {
      washRequest.wasServicePlanChanged = false;
      washRequest.servicePlan =
        this.servicePlanObjectOnError.recommendedServicePlan;
      washRequest.reasonOfChange = this.reasonOfChange.value.displayName;
      washRequest.reasonOfChangeCode = this.reasonOfChange.value.code;
      return washRequest;
    }

    const selectedServicePlanName =
      this.servicePlanObjectOnError.servicePlan &&
      this.servicePlanObjectOnError.servicePlan.servicePlanName;
    const wasPreviousServicePlanSelected =
      this.washType.value.planName === selectedServicePlanName;
    if (wasPreviousServicePlanSelected) {
      washRequest.wasServicePlanChanged = this.wasWashTypeChanged.value;
      washRequest.servicePlan = this.servicePlanObjectOnError.servicePlan;
      washRequest.reasonOfChange = this.reasonOfChange.value.displayName;
      washRequest.reasonOfChangeCode = this.reasonOfChange.value.code;
      return washRequest;
    }

    const standardServicePlanName =
      this.servicePlanObjectOnError.standardServicePlan &&
      this.servicePlanObjectOnError.standardServicePlan.servicePlanName;
    const wasStandardServicePlanSelected =
      this.washType.value.planName === standardServicePlanName;
    if (wasStandardServicePlanSelected) {
      delete washRequest.servicePlan;
      washRequest.wasServicePlanChanged = this.wasWashTypeChanged.value;
      washRequest.servicePlan =
        this.servicePlanObjectOnError.standardServicePlan;
      washRequest.reasonOfChange = this.reasonOfChange.value.displayName;
      washRequest.reasonOfChangeCode = this.reasonOfChange.value.code;
      return washRequest;
    }
  }

  private buildFormRequest(): WashRequest {
    const washRequest = new WashRequest({
      terminalId: this.warehouseId.value,
      operatedById: this.customerId.value,
      serviceTypeId: this.obServiceTypeId,
      tankId: this.containerId,
      foodGrade: !!this.foodGrade.value,
      kosher: !!this.kosher.value,
      exteriorWash: !!this.exteriorWash.value,
      serviceTypeOntraxId: this.serviceTypeId.value,
      tankTypeId: this.containerTypeId,
    });

    if (this.foodGrade.value) {
      washRequest.lastContainedProduct1Id = this.lastContainedProduct1Id.value;
      washRequest.lastContainedProduct2Id = this.lastContainedProduct2Id.value;
      washRequest.lastContainedProduct3Id = this.lastContainedProduct3Id.value;
    } else {
      washRequest.lastContainedProductComp1Id =
        this.lastContainedProductComp1Id.value;
      washRequest.lastContainedProductComp2Id =
        this.lastContainedProductComp2Id.value;
      washRequest.lastContainedProductComp3Id =
        this.lastContainedProductComp3Id.value;
      washRequest.lastContainedProductComp4Id =
        this.lastContainedProductComp4Id.value;
      washRequest.lastContainedProductComp5Id =
        this.lastContainedProductComp5Id.value;
    }
    return washRequest;
  }

  private removeNullProducts(washRequest: WashRequest, fields: string[]) {
    fields.forEach((field) => {
      if (washRequest[field] === null) {
        delete washRequest[field];
      }
    });
  }

  /**
   *
   * @param updateServicePlan If the service plan value should be overwritten or not
   * @param originalWashRequest The request that is being edited
   */
  async getServicePlanData(
    updateServicePlan?: boolean,
    originalWashRequest?: WashRequest
  ) {
    this.washType.enable();
    this.servicePlanObject = null;
    this.servicePlanList = [];
    this.actionDisclaimer = '';
    this.canSubmitRequest = true;
    this.washType.enable();

    const washRequest = originalWashRequest
      ? new WashRequest({
          ...originalWashRequest,
          operatedById: this.item.customerId,
          tankId: this.item.containerId,
          serviceTypeId: this.item.serviceTypeId,
          exteriorWash: !!this.exteriorWash.value,
          tankTypeId: this.containerTypeId,
          serviceTypeOntraxId: this.serviceTypeId.value,
        })
      : this.buildFormRequest();

    // API not allow null values
    const fieldsToVerify = [
      'lastContainedProduct1Id',
      'lastContainedProduct2Id',
      'lastContainedProduct3Id',
      'lastContainedProductComp1Id',
      'lastContainedProductComp2Id',
      'lastContainedProductComp3Id',
      'lastContainedProductComp4Id',
      'lastContainedProductComp5Id',
    ];

    this.removeNullProducts(washRequest, fieldsToVerify);

    if (washRequest.hasServiceRequestData) {
      if (this.isStatusBeforeSubmit()) {
        this.changeFieldsStatus(ServicePlanRelatedFields, 'disable');
      }

      this.isLoadingServicePlan = true;
      try {
        const spData =
          await this.washListService.getServicePlanData(washRequest);
        this.handleServicePlanResponse(spData, updateServicePlan);
        this.flareRequired = spData.flareInfo.flareRequired;
      } catch (error) {
        this.handleServicePlanResponseError(error, updateServicePlan);
        this.flareRequired = false;
      }

      this.isLoadingServicePlan = false;

      if (this.isStatusBeforeSubmit()) {
        this.changeFieldsStatus(ServicePlanRelatedFields, 'enable');
      }
    }
  }

  private setProductLineQuantityOnControl(
    servicePlan,
    customerProductName,
    formControl
  ) {
    const productLines = servicePlan && servicePlan.productLines;

    if (productLines) {
      const productLine = productLines.find(
        (productLine) => productLine.customerProductName === customerProductName
      );
      const quantity = productLine ? productLine.quantity : 0;
      formControl.setValue(quantity);
    }
  }

  private handleServicePlanResponse(
    spData: ServicePlan,
    updateServicePlan?: boolean
  ) {
    this.servicePlanObject = spData;
    this.servicePlanList = spData.servicePlanOptions;
    this.recommendedWashType.setValue(this.servicePlanObject.servicePlanName);

    if (!this.hosesQty.value) {
      this.setProductLineQuantityOnControl(
        this.servicePlanObject,
        'Hose Clean',
        this.hosesQty
      );
    }

    if (!this.pumpQty.value) {
      this.setProductLineQuantityOnControl(
        this.servicePlanObject,
        'Pump Clean',
        this.pumpQty
      );
    }

    if (!this.fittingsQty.value) {
      this.setProductLineQuantityOnControl(
        this.servicePlanObject,
        'Fitting Clean',
        this.fittingsQty
      );
    }

    // Store the recommended Service Plan
    this.recommendedServicePlanData = { ...spData };
    delete this.recommendedServicePlanData.servicePlanOptions;
    this.simplifiedServicePlan = this.buildSimplifiedServicePlan(
      this.recommendedServicePlanData
    );

    if (updateServicePlan) {
      this.washType.setValue(this.simplifiedServicePlan);
    } else {
      this.loadServicePlanData();
    }
    return;
  }

  private handleServicePlanResponseError(
    error: { response: { status: number; data: any } },
    updateServicePlan?: boolean
  ) {
    const responseServicePlan = error.response.data;
    const openBravoErrorWithDummy: boolean =
      error.response.status === 417 && !!responseServicePlan.servicePlanName;

    if (!this.hasQualaRole) {
      this.washType.disable();

      if (updateServicePlan && responseServicePlan.servicePlanName) {
        this.simplifiedServicePlan =
          this.buildSimplifiedServicePlan(responseServicePlan);
        this.washType.setValue(this.simplifiedServicePlan);
        return;
      }
      this.washType.setValue({
        planName: StandardServicePlanNames.createdByDispatcher,
        planDuration: StandardServicePlanDuration.default,
      });
      return;
    }

    if (
      openBravoErrorWithDummy &&
      ((!this.recommendedWashType.value && !updateServicePlan) ||
        updateServicePlan)
    ) {
      // If the dummy service plan is returned and there is no serviceplan on this request
      // or the request had changed, should set the dummy as selected and recommended
      // allowing the user to proceed with the request
      this.warningIfUnknownError(responseServicePlan);
    } else if (openBravoErrorWithDummy && !updateServicePlan) {
      // If the dummy service plan is returned, should give the user the option to choose
      // between the recommended service plan, the selected and the dummy
      this.buildServicePlanObjectOnError(responseServicePlan);
      this.genericWarningIfUnknownError();
    } else if (error.response.status === 400) {
      // If returns error 400 it means:
      // - Permit error or Restricted Content
      // - DO NOT CLEAN; DO NOT HEAT;
      // - no Plan found for DO NOT CLEAN _OR_ DO NOT HEAT;
      this.warningCreationIfRestrictedError(responseServicePlan.message);
    } else {
      // If an error 404 or unknown error has happened
      // Block the creation
      this.blockSubmissionIfError();
    }
  }

  private warningCreationIfRestrictedError(errorMessage: string) {
    this.toastr.warning(
      errorMessage ? errorMessage : 'Products cannot be cleaned',
      'Unable to get service plan.'
    );

    if (this.hasQualaRole) {
      this.recommendedWashType.setValue(
        StandardServicePlanNames.servicePlanNotFound
      );
    } else {
      this.recommendedWashType.setValue(
        StandardServicePlanNames.createdByDispatcher
      );
    }

    if (this.hasQualaRole || this.isFoodGrade.value) {
      this.actionDisclaimer =
        `${errorMessage} You can still save this request as Draft`
          .split('. ')
          .join('.\n');
    }

    this.wasWashTypeChanged.disable();
    this.canSubmitRequest = false;
  }

  private blockSubmissionIfError() {
    this.recommendedWashType.setValue(
      StandardServicePlanNames.servicePlanNotFound
    );
    this.washType.markAsDirty();
    this.toastr.warning(
      'Please review the Container, and Product Name.',
      'Unable to get a Service Plan.'
    );
    this.actionDisclaimer =
      'Please review the following information: Container, and Product Name.\n' +
      'If the desired service plan does not appear, it means that a Service Plan has not been added for this content.\n' +
      'Contact EHS to get a service plan added.';
    this.wasWashTypeChanged.disable();
    this.canSubmitRequest = false;
  }

  private warningIfUnknownError(standardServicePlan: ServicePlan) {
    this.recommendedWashType.setValue(StandardServicePlanNames.createdByQuala);
    this.washType.setValue(
      this.buildSimplifiedServicePlan(standardServicePlan)
    );
    this.washType.markAsDirty();
    this.toastr.warning('', 'Unable to reach OpenBravo to get a Service Plan.');
    this.actionDisclaimer =
      'Unable to reach OpenBravo to get a Service Plan.\n' +
      'A Standard Service Plan will be used with 90 minutes of duration for scheduling purposes.';
    this.canSubmitRequest = true;
  }

  private genericWarningIfUnknownError() {
    this.washType.markAsDirty();
    this.toastr.warning('', 'Unable to reach OpenBravo to get a Service Plan.');
    this.actionDisclaimer =
      'Unable to reach OpenBravo to get a Service Plan.\n' +
      'You can select a Standard Service Plan with 90 minutes of duration for scheduling purposes.\n' +
      'Or select the Previous or the Recommended Service Plan, if available.';
    this.canSubmitRequest = true;
  }

  private buildSimplifiedServicePlan(servicePlanData: ServicePlan) {
    if (
      servicePlanData &&
      servicePlanData.servicePlanName &&
      servicePlanData.servicePlanDurationMinutes
    ) {
      return {
        planName: servicePlanData.servicePlanName,
        durationMinutes: servicePlanData.servicePlanDurationMinutes,
        planID: servicePlanData.servicePlanID,
      };
    }
  }

  private buildServicePlanObjectOnError(standardServicePlan: ServicePlan) {
    this.servicePlanList = [
      this.buildSimplifiedServicePlan(this.item.recommendedServicePlan),
      this.buildSimplifiedServicePlan(this.item.servicePlan),
      this.buildSimplifiedServicePlan(standardServicePlan),
    ];
    this.servicePlanList = this.servicePlanList
      .filter((value) => value !== undefined)
      .filter(
        (servicePlan, index, completeArray) =>
          completeArray.findIndex(
            (findServicePlan) =>
              (findServicePlan && findServicePlan.planName) ===
              servicePlan.planName
          ) === index
      );

    this.servicePlanObjectOnError = {
      recommendedServicePlan: this.item.recommendedServicePlan,
      servicePlan: this.item.servicePlan,
      standardServicePlan,
    };
  }

  getWashRevisionItems() {
    this.washItemService
      .getWashRequestRevision(this.item.id)
      .then((data: any) => {
        this.washRevisionItems = data;
      })
      .catch((error) => {});
  }

  private findRevisionItem(field) {
    return this.washRevisionItems.revisionItems.filter(
      (item) => `${item.field}Id` === field
    );
  }

  getOriginalFieldValue(field): string {
    const revisionItem = this.findRevisionItem(field);
    return revisionItem[0].original;
  }

  fieldShouldBeRevised(field): boolean {
    if (!this.washRevisionItems || this.item[field]) {
      return false;
    }
    const revisionItem = this.findRevisionItem(field);
    return revisionItem.length > 0;
  }

  displayServicePlanOverlay(
    actionHeader: string,
    actionLabel: string,
    disclaimerText: string,
    washRequest: WashRequest,
    canSubmit: boolean,
    customerComments?: string
  ) {
    const washRequestSubmitted =
      washRequest.status === WashRequestStatus.Submitted;
    const actionDisclaimer =
      washRequestSubmitted && !disclaimerText
        ? popUpDisclaimers.submit
        : disclaimerText;

    const dialogRef = this.dialog.open<
      ServicePlanPopUpOverlayComponent,
      ServicePlanPopUpInputModel
    >(ServicePlanPopUpOverlayComponent, {
      data: {
        actionHeader,
        actionLabel,
        actionDisclaimer,
        washRequest,
        canSubmit,
        customerComments,
      },
      panelClass: 'service-plan-popup-overlay',
    });

    dialogRef.afterClosed().subscribe((overlayData) => {
      if (!overlayData.submit) {
        this.isProcessing = false;
        return;
      }
      this.editWashRequest(washRequest);
    });
  }

  handleExteriorWashToggle(selectedTank) {
    // If no Tank is selected, all service option should be available and enabled
    if (!selectedTank || !selectedTank.containerTypeId) {
      this.washListService.toggleServiceTypeOptions(true);
      this.exteriorWash.enable();
      return;
    }

    const exteriorWashProduct =
      this.washListService.dataStore.exteriorWashProducts.find(
        (item) => item.containerTypeId === selectedTank.containerTypeId
      );
    const exteriorWashOnlyServiceId =
      this.washListService.dataStore.serviceTypes.find(
        (item) => item.key === ServiceTypeKeys.ExteriorWashOnly
      ).id;
    if (!exteriorWashProduct) {
      this.exteriorWash.setValue(false);
      this.exteriorWash.disable();
      this.washListService.toggleServiceTypeOptions(false, [
        ServiceTypeKeys.ExteriorWashOnly,
      ]);
      if (this.serviceTypeId.value === exteriorWashOnlyServiceId) {
        const cleanServiceId = this.washListService.dataStore.serviceTypes.find(
          (item) => item.key === ServiceTypeKeys.Clean
        ).id;
        this.serviceTypeId.setValue(cleanServiceId);
      }
    } else {
      this.washListService.toggleServiceTypeOptions(true);
      if (this.serviceTypeId.value === exteriorWashOnlyServiceId) {
        this.exteriorWash.setValue(true);
        this.exteriorWash.disable();
      } else {
        this.exteriorWash.enable();
      }
    }
  }

  cleanBillToTerminal() {
    this.washBillToTerminalId.reset();
  }
}
