import { Component, Inject, Optional } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-wash-reject',
  templateUrl: './wash-reject.component.html',
  styleUrls: ['./wash-reject.component.scss'],
})
export class WashRejectComponent {
  rejectForm = this.fb.group({
    reason: ['', Validators.required],
  });

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WashRejectComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public reasons: any
  ) {}

  cancelDialog() {
    this.dialogRef.close();
  }

  submitDialog() {
    if (this.rejectForm.valid) {
      const code = this.rejectForm.get('reason').value;
      const { displayName } = (this.reasons || []).find(
        (item) => item.code === code
      );
      this.dialogRef.close({ code, label: displayName });
    }
  }
}
