// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-list-base .mat-list-option {
  font-size: 12px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/checkbox-list/checkbox-list.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF","sourcesContent":[".mat-list-base .mat-list-option {\n  font-size: 12px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
