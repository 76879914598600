import { Component, Inject, Optional } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-ncr-cancel',
  templateUrl: './ncr-cancel.component.html',
  styleUrls: ['./ncr-cancel.component.scss'],
})
export class NcrCancelComponent {
  cancelForm = this.fb.group({
    cancelReason: ['', Validators.required],
  });

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NcrCancelComponent>
  ) {}

  cancelDialog() {
    this.dialogRef.close();
  }

  submitDialog() {
    if (this.cancelForm.valid) {
      const reason = this.cancelForm.get('cancelReason').value;
      this.dialogRef.close(reason);
    }
  }
}
