import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CheckboxListComponent),
  multi: true,
};

@Component({
  selector: 'app-checkbox-list',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit, ControlValueAccessor {
  private onChange: (_: any) => any;
  private onTouched: () => any;

  disabled: boolean;
  value: string[];

  @Input() optionsList: Array<string>;
  constructor() {}

  ngOnInit(): void {}

  writeValue(value: string[] = []): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelection(data: SelectionModel<MatListOption>) {
    this.value = data.selected.map((item) => item.value);
    this.onChange(this.value);
    this.onTouched();
  }
}
