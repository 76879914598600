import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../core/auth/auth.service';
import { NewsItem, NewsItemView } from './news-item/news-item.model';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
})
export class NewsFeedComponent implements OnInit {
  isQualaWorker: boolean = this.authService.hasQualaWorkerRole();
  newsItems: NewsItem[] = [
    {
      id: 27,
      qualaWorkerOnly: true,
      date: '08/27/2024',
      title: `New feature: Recent operators list`,
      newsContent: `
        <p>In this new feature, when a user starts a wash request, the most recent operators will appear in a new section called "Recent operators".</p>
        <p>This allows the user to find the operators more easily.</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/operators-recently-picked.png',
    },
    {
      id: 26,
      qualaWorkerOnly: true,
      date: '08/27/2024',
      title: `Actual Arrival Time`,
      newsContent: `
        <p>Thinking about data accuracy, it is now possible to specify exactly when a container arrived in the warehouse. To do this, simply edit a wash request and enter the actual arrival time.</p>
        <p>Note: When you edit the arrival time, it does not mean that the system will automatically recalculate the best time to wash the tank, and a manual rescheduling may be necessary. We are continuously working to enhance the agenda experience, and the automatic rescheduling will be available soon. </p>
        `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/trailer-arrival-time.png',
    },
    {
      id: 25,
      qualaWorkerOnly: true,
      date: '08/08/2024',
      title: `Product Washing Verification`,
      newsContent: `
        <p>When customers submit wash requests in OnTrax, the system will now alert customers if a product has not been washed by the terminal in the last 12 months.</p>
        <p>This allows for better communication and planning, anticipating potential problems and minimizing operational risks.</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/common-product.png',
    },
    {
      id: 24,
      qualaWorkerOnly: true,
      date: '07/06/2024',
      title: `New feature: Estimated Completion Time update notification`,
      newsContent: `
        <p>OnTrax will start tracking customers visualization of all requests Estimated Completion Time.</p>
        <p>Now, when the completion date/time  is updated, the customers that have already viewed the previous value, will be automatically notified of the new expected completion time.</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/customer-ect.jpg',
    },
    {
      id: 23,
      qualaWorkerOnly: true,
      date: '07/19/2024',
      title: `Removal of the B prefix for Depot terminals`,
      newsContent: `
        <p>From now on, Depot terminals will no longer use the 'B' prefix.</p>
        <p>In the list and when creating a new NCR, Depot terminals will be distinguished by the word 'Depot'.</p>
      `,
      shouldRender: true, // We are waiting until the client give us the date that it should be in the home screen
      imageUrl: '../../../../../../assets/images/ncr-remove-b.png',
    },
    {
      id: 22,
      qualaWorkerOnly: true,
      date: '07/15/2024',
      title: `New Feature: Flex Inspection Tooltip`,
      newsContent: `
        <p>We’ve added a new feature to assist with Food Grade requests.</p>
        <p>When a wash request in progress is completed, if it’s identified as Food Grade and requires a flex inspection form, Ontrax will notify you with a tooltip.</p>
        <p>You can choose to click on the tooltip to fill out the form immediately, or you can wait for it to open automatically. If no form filling request appears, no further action is needed.</p>
      `,
      shouldRender: false, // We are waiting until the client give us the date that it should be in the home screen
      imageUrl: '../../../../../../assets/images/flex-inspection.png',
    },
    {
      id: 21,
      qualaWorkerOnly: true,
      date: '07/11/2024',
      title: `New Feature: Segregate regions and line of businesses for NCR flow`,
      newsContent: `
      <p>The lines of business (LoB) and regions are now separated.</p>
      <p>Therefore, when assigning an NCR, if it is a terminal with only one LoB, the business partner field will be pre-filled and blocked with the LoB relative to this terminal.</p>
      <p>In the case of assigning an NCR to a terminal that has multiple LoBs, the BP field will have a list of the LoBs related to this terminal to choose from.
      </p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/ncr-one-lob.png',
    },
    {
      id: 20,
      qualaWorkerOnly: true,
      date: '06/17/2024',
      title: `Non conformity report is now available for every Line of Business`,
      newsContent: `
      <p>Now it is possible to create NCRs (Non-conformity reports) for any line of business. To do this, select the desired Customer in the 'Business Partner / Company Name *' field.</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/ncr-all-lob.png',
    },
    {
      id: 19,
      qualaWorkerOnly: false,
      date: '06/05/2024',
      title: `Wilmington, NC Closure`,
      newsContent: `
      <p>Starting from Friday, 5/24, the Wilmington NC location is closed.</p>
        <p>As we wind down the operations in this location, we will not be accepting any new service jobs and will be referring our parts & service customers to nearby branches.</p>
      `,
      shouldRender: true,
    },
    {
      id: 18,
      qualaWorkerOnly: true,
      date: '05/17/2024',
      title: `Improvements on Assign screen`,
      newsContent: `
      <p>The assign screen now will not have a dropdown to search for terminals. Instead, we are displaying by default the managers that belongs to the terminal the NCR was created.</p>
      <p>You can still assign an NCR to a manager from other terminal, just check the 'Show All Managers' checkbox and they'll be displayed.</p>
      <p>Additionally, typing the terminal number / manager name will also filter the results for the assignee dropdown. </p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/assign-screen.jpg',
    },
    {
      id: 17,
      qualaWorkerOnly: false,
      date: '05/17/2024',
      title: `Show terminals from all line of business`,
      newsContent: `
      <p>We've changed how we display terminals in the NCR's related flows. Now you can see the line of business along with the terminal name, while creating and editing an NCR.</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/terminal-name-with-lob.jpg',
    },
    {
      id: 16,
      qualaWorkerOnly: true,
      date: '04/18/2024',
      title: `Terminal Scorecard now available inside OnTrax`,
      newsContent: `
      <p>Now operations team will be able to access the brand new automated Terminal Scorecard without leaving Ontrax. The new report "Terminal Scorecard" is now available under the "Reports" menu option, with updated data about terminal's KPI related to safety, quality, revenue, costs, productivity, and more.</p>
      `,
      shouldRender: true,
      imageUrl:
        '../../../../../../assets/images/terminal-scorecard-wash-announcement.jpg',
    },
    {
      id: 15,
      qualaWorkerOnly: true,
      date: '04/01/2024',
      title: `Requests generated in Etendo will be displayed in Ontrax`,
      newsContent: `
      <p>When a user creates wash requests in Etendo as "Sales Orders," those requests are automatically generated in Ontrax. If feasible, they will also be automatically scheduled by the system. </p>
      <p>Currently, only wash requests are automatically generated.</p>
      <p>These requests will feature a distinctive icon when showcased in the list, as illustrated in the image above..</p>
      `,
      shouldRender: true,
      imageUrl: '../../../../../../assets/images/request-created-in-etendo.png',
    },
    {
      id: 13,
      qualaWorkerOnly: true,
      date: '03/21/2024',
      title: `Killdeer, ND Closure`,
      newsContent: `
        <p>To our Valued Customers,</p>

        <p> We have made the difficult decision to close our <b>Killdeer ND location for Tank Wash & Maintenance starting Friday 3/15/24</b></p>

        <p>As we wind down the operations in this location, we will not be accepting any new service jobs and will be referring our parts & service customers to nearby branches.</p>
      `,
      shouldRender: true,
    },
    {
      id: 12,
      qualaWorkerOnly: true,
      date: '03/14/2024',
      title: `Confined Space Entry (CSE) Information in Wash List`,
      newsContent: `
        <p>Starting now, users can add Confined Space Entry (CSE) information in the wash list: Completed, Hold, Expired Requests, and Pending.</p>
        <p>If the information has been entered previously and needs editing, you can make changes.</p>
        <p><b>How to use:</b></p>
        <ul>
          <li>Find this functionality in one of the mentioned workflows.</li>
          <li>When clicking on a wash request, you'll notice the CSE button.</li>
          <li>Fill in the requested information.</li>
          <li>Click the save button.</li>
        </ul>
        <p>Following these steps, you'll have successfully filled in the CSE information</p>
      `,
      imageUrl: '../../../../../../assets/images/cse-new-flows.png',
      shouldRender: true,
    },
    {
      id: 11,
      qualaWorkerOnly: false,
      date: '03/11/2024',
      title: 'Tank Clean Rates Adjustment',
      newsContent: `
        <p>We are implementing a 4% price increase on Tank Cleaning Base rates on April 1, 2024.</p>
        <p>Waste disposal costs continue to well outpace inflation which remains a significant driver of our costs. As a result, Heel rates will increase approximately 10%.</p>
        <p>This only pertains to Tank cleaning rates in the U.S.</p>
      `,
      newsType: 'ExpiringNotification',
      shouldRender:
        new Date().getTime() >=
        new Date(Date.UTC(2024, 2, 11, 12, 0, 0, 0)).getTime(), // March 11, 2024 7am EST in UTC
      maxViewsPerUser: 1,
    },
    {
      id: 10,
      qualaWorkerOnly: false,
      date: '02/27/2024',
      title: `System Maintenance Notice`,
      newsContent: `
        <p>We would like to inform you that the maintenance scheduled for last night could not take place, due to issues in Etendo upgrades. Therefore, it will occur today, Tuesday. Our system will undergo scheduled maintenance for improvements and updates. During this period, the system will be temporarily unavailable.</p>
        <p><b>** Maintenance Date and Time:</b> 02/27, 9 pm EST</p>
        <p><b>** Estimated duration:</b> 59 minutes</p>
      `,
      shouldRender: true,
    },
    {
      id: 9,
      qualaWorkerOnly: false,
      date: '02/26/2024',
      title: `System Maintenance Reschedule Notice`,
      newsContent: `
        <p>We would like to inform you that, the maintenance that was scheduled to last Sunday, 02/25, was rescheduled to today, 02/26. Our system will undergo scheduled maintenance for improvements and, during this period, the system will be temporarily unavailable.</p>
        <p><b>** Maintenance Date and Time:</b> 02/26, 9 pm EST</p>
        <p><b>** Estimated duration:</b> 1 hour</p>
      `,
      shouldRender: true,
    },
    {
      id: 8,
      qualaWorkerOnly: false,
      date: '02/22/2024',
      title: `System Maintenance Notice`,
      newsContent: `
        <p>We would like to inform you that, in the next Sunday, our system will undergo scheduled maintenance for improvements and updates. During this period, the system will be temporarily unavailable.</p>
        <p><b>** Maintenance Date and Time:</b> 02/25, 9 pm EST</p>
        <p><b>** Estimated duration:</b> 30 minutes</p>
      `,
      shouldRender: true,
    },
    {
      id: 7,
      qualaWorkerOnly: true,
      date: '02/20/2024',
      title: `Upload of the Confined Space Entry (CSE) related files in OnTrax`,
      newsContent: `
        <p>We improved OnTrax to upload, for every CSE, a file.</p>
        <p><b>How to use:</b></p>
        <p> ** Click on "Select a file to upload"</p>
        <p> ** Choose a digital version of any file related to Confined Space Entry, such as the Permit file.</p>
        <p>You can find more details in the <a target=”_blank” href="https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/using-ontrax/6-managing-the-schedule/finishing-a-wash-request">Ontrax Wiki.<a/></p>
      `,
      shouldRender: true,
      imageUrl:
        '../../../../../../assets/images/cse-file-upload-announcement.jpg',
    },
    {
      id: 6,
      qualaWorkerOnly: true,
      date: '02/20/2024',
      title: `Change Start/Stop time for every completed and expired request`,
      newsContent: `
        <p>We improved OnTrax to enable Terminal Managers and CSCs to change the Start/Stop time of all completed and expired requests.</p>
        <p>Sometimes the time the user Start/Stop a wash does not reflect what really happened in the operation and, because of that, you should be able to add a new date for Start/Stop and provide an input on why this change is being done.</p>
        <p><b>How to use:</b></p>
        <p>** <b>If a request is completed:</b>
        <p>* Click on "Edit" button</p>
        <p>* Fill Start/Stop confirmation dialog</p>
        <p>* Fill the reason of change</p>
        <p>** <b>If request is expired:</b></p>
        <p>* Click on "Finish Request" button</p>
        <p>* Confirm the operator name</p>
        <p>* Fill Start/Stop confirmation dialog</p>
        <p>* Fill the reason of change</p>
        <p>You can find more details in the:</p>
        <p>** <a target=”_blank” href="https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/using-ontrax/6-managing-the-schedule/finishing-a-wash-request">Expired section <a/></p>
        <p>** <a target=”_blank” href="https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/using-ontrax/5-managing-requests/editing-a-request">Editing a request section <a/></p>
      `,
      shouldRender: true,
    },
    {
      id: 5,
      qualaWorkerOnly: true,
      date: '02/01/2024',
      title: `New fields in Start/Stop confirmation: Confined Space Entry (CSE)`,
      newsContent: `
        <p>We are adding new fields within the Start/Stop confirmation window.</p>
        <p>Now we have a field where information about Confined Space Entry (CSE) will be collected.</p>
        <p><b>How to use:</b></p>
        <p> ** <b>Executed Field</b>: Did the operator need to enter inside the container? Check yes or no.</p>
        <p>If there was no need for CSE, you can proceed to the Adjust Start/Stop fields.</p>
        <p>If there was a CSE, two more fields will be enabled for filling:</p>
        <p> ** <b>Type</b>: Did the operator need to enter partially (head or part(s) of the body) or completely (whole body) into the container? Mark full body or partial.</p>
        <p> ** <b>Work Performed By</b>: Select the operator's name from the list. If the operator's name is not listed, type the operator's name and proceed to the Adjust Start/Stop fields.</p>
        <p>Now just click on save.</p>
      `,
      shouldRender: true,
    },
    {
      id: 4,
      qualaWorkerOnly: true,
      date: '01/30/2024',
      title: `New NCR feature: now you can return NCR's to the 'In Analysis' status`,
      newsContent: `
        <p>We are introducing a new step on the NCR workflow: <b>Return to analysis</b>.</p>
        <p>An NCR in 'Waiting for Approval' status: you are be able to return it to the 'In Analysis' status, reassigning it to a new user.</p>
        <p>The new assignee will be notifyed via email.</p>
        <p><b>How to use:</b></p>
        <p>&nbsp &nbsp ** On the 'Waiting for Approval' tab, click on the desired NCR and then click 'Return to Analysis'.</li>
        <p>&nbsp &nbsp ** Select the terminal, new assignee and Return/Reject Comments</li>
        <p>&nbsp &nbsp ** Click on submit to return the NCR to 'Analysis', and notify the new assignee</p>
        <p>You can find more details in the <a target=”_blank” href="https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/non-conformity-report-ncr/ncr-flow-and-life-cycle?authuser=0">Ontrax Wiki<a/></p>.
      `,
      shouldRender: true,
    },
    {
      id: 3,
      qualaWorkerOnly: false,
      date: '01/26/2024',
      title: 'Ceasing tank wash operations at our Rock Hill, SC location',
      newsContent: `
        <p>As of today, 1/26/24, DCI will be ceasing tank wash operations at our Rock Hill, SC location. The location will remain under DCI's ownership as an administrative office. If you are in the area and need a wash please find the nearest facility at <a href='https://quala.us.com/locations-list'>Ontax Wiki</a>.</p>
      `,
      shouldRender:
        new Date().getTime() >=
        new Date(Date.UTC(2024, 0, 26, 12, 0, 0, 0)).getTime(), // January 26, 2024 7am EST in UTC
    },
    {
      id: 2,
      qualaWorkerOnly: true,
      date: '01/08/2024',
      title: `OnTrax now allows you to correct Start and Stop times!`,
      newsContent: `
        <p>We improved the Stop confirmation pop-up where you will be able to adjust the start or complete time of a request.</p>
        <p>In any event where the start or stop time are different from the real execution time, you'll be able to inform the correct date/time on the pop-up before confirming the completion of the request.</p>
        <p><b>How to use:</b></p>
        <p>&nbsp &nbsp ** On schedule tab, click on the stop button of the desired card</li>
        <p>&nbsp &nbsp ** Adjust the start or the complete time to the actual ones, if needed</li>
        <p>&nbsp &nbsp ** Click on Save to confirm your changes</p>
        <p>You can find more details in the <a target=”_blank” href="https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/using-ontrax/6-managing-the-schedule/finishing-a-wash-request">Ontrax Wiki<a/></p>.
      `,
      imageUrl: '../../../../../../assets/images/start-stop-announcement.jpg',
      shouldRender: true,
    },
    {
      id: 1,
      qualaWorkerOnly: false,
      date: '01/04/2024',
      title: `OnTrax Introduces the new Feed for Updates and Information!`,
      newsContent: `
        <p>We are excited to introduce you the new OnTrax Feed – your go-to source for the latest updates, news, and information.</p>
        <p>Designed to keep you in the loop and provide valuable insights into our ever-evolving system.</p>
        <p>Keeping in mind the importance of always having you up-to-date with our latest developments - whether it's important announcements, feature releases, or insightful articles - the OnTrax Feed will be your destination for staying informed and connected.</p>
        <p>Explore the OnTrax Feed regularly to discover a wealth of information that will enhance your OnTrax experience!</p>
        <p>We look forward to this new chapter of interaction and discovery with you.</p>
      `,
      shouldRender: true,
    },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const currentUserNewsViews =
      localStorage.getItem('user_news_items_views') || '[]';
    const parsedCurrentUserNewsViews: Array<NewsItemView> =
      JSON.parse(currentUserNewsViews);

    this.newsItems = this.newsItems.filter((newsItem) => {
      const maxViewsPerUser = newsItem.maxViewsPerUser;

      if (maxViewsPerUser) {
        const matchingViews = parsedCurrentUserNewsViews.filter(
          (userNewsItemView) => {
            return (
              userNewsItemView.newsItemId === newsItem.id &&
              userNewsItemView.username === this.authService.getUser().username!
            );
          }
        );

        return matchingViews.length < maxViewsPerUser;
      }

      return true;
    });
  }
}
