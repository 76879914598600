import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ServicePlanPopUpInputModel } from './service-plan-pop-up.model';
import { popUpDisclaimers } from './service-plan-pop-up.constants';
import { StandardServicePlanNames } from '../../wash-item/wash-item.model';
import { WashListService } from '../../wash-list.service';
import { ServiceTypeKeys } from '../../wash-create/wash-create.model';
import { WashRequest } from '../../wash-list.model';
import { temperatureUnits } from '../../wash-create/steam-heat-data-form/steam-heat-data-form.component.model';

@Component({
  selector: 'app-service-plan-pop-up-overlay',
  templateUrl: './service-plan-pop-up-overlay.component.html',
  styleUrls: ['./service-plan-pop-up-overlay.component.scss'],
})
export class ServicePlanPopUpOverlayComponent implements OnInit, OnDestroy {
  actionHeader: string;
  actionLabel: string;
  secondaryActionLabel?: string;
  actionDisclaimer: string;
  disableSubmit = false;
  washRequestSubmitInProcess = false;

  averageAutomatedConversionTime?: string;

  containerNumber: string;
  tankType: string;
  lastContainedCompartment1: string;
  lastContainedCompartment2: string;
  lastContainedCompartment3: string;
  lastContainedCompartment4: string;
  lastContainedCompartment5: string;
  servicePlanName: string;
  recommendedServicePlanName: string;
  reasonOfChange: string;
  lastUpdateTime: number;
  lastUpdatedBy: string;
  customerComments: string;

  washRequest: WashRequest;
  temperatureSymbol: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public servicePlanPopUpInputModel: ServicePlanPopUpInputModel,
    private dialogRef: MatDialogRef<ServicePlanPopUpOverlayComponent>,
    private washListService: WashListService
  ) {}

  ngOnDestroy(): void {
    this.washRequestSubmitInProcess = false;
  }

  ngOnInit(): void {
    this.popUpDetails();
    this.checkIfCanSubmit();
  }

  get isSteamRequest() {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) =>
        item.id ===
        this.servicePlanPopUpInputModel.washRequest.serviceTypeOntraxId
    );

    if (!serviceType) {
      return false;
    }

    return serviceType.key === ServiceTypeKeys.SteamHeat;
  }

  washRequestDetails() {
    this.washRequest = this.servicePlanPopUpInputModel.washRequest;

    this.containerNumber = this.washRequest.containerNumber;
    this.tankType = this.washRequest.containerType;
    this.customerComments =
      this.washRequest.customerComments ||
      this.servicePlanPopUpInputModel.customerComments;

    if (
      this.washRequest.steamHeatData &&
      this.washRequest.steamHeatData.temperatureUnit
    ) {
      const temperatureUnit = temperatureUnits.find(
        (unit) => unit.value === this.washRequest.steamHeatData.temperatureUnit
      );
      this.temperatureSymbol = temperatureUnit.symbol;
    }

    if (this.washRequest.lastUpdateTime && this.washRequest.lastUpdatedBy) {
      this.lastUpdateTime = this.washRequest.lastUpdateTime;
      this.lastUpdatedBy = this.washRequest.lastUpdatedBy;
    }

    if (this.washRequest.foodGrade) {
      this.lastContainedCompartment1 =
        this.washRequest.lastContainedProduct1Name;
      this.lastContainedCompartment2 =
        this.washRequest.lastContainedProduct2Name;
      this.lastContainedCompartment3 =
        this.washRequest.lastContainedProduct3Name;
    } else {
      this.lastContainedCompartment1 =
        this.washRequest.lastContainedProductComp1Name;
      this.lastContainedCompartment2 =
        this.washRequest.lastContainedProductComp2Name;
      this.lastContainedCompartment3 =
        this.washRequest.lastContainedProductComp3Name;
      this.lastContainedCompartment4 =
        this.washRequest.lastContainedProductComp4Name;
      this.lastContainedCompartment5 =
        this.washRequest.lastContainedProductComp5Name;
    }

    if (this.washRequest.servicePlan) {
      this.servicePlanName = this.washRequest.servicePlan.servicePlanName;

      const isErrorCreatedByDispatcher =
        this.servicePlanName === StandardServicePlanNames.createdByDispatcher;
      if (isErrorCreatedByDispatcher) {
        this.actionDisclaimer = popUpDisclaimers.servicePlan;
        this.disableSubmit = true;
      }

      if (this.washRequest.wasServicePlanChanged) {
        this.recommendedServicePlanName =
          this.washRequest.recommendedServicePlan.servicePlanName;
        this.reasonOfChange = this.washRequest.reasonOfChange;
      }
    } else {
      if (
        Object.values(popUpDisclaimers).includes(this.actionDisclaimer || '')
      ) {
        this.actionDisclaimer = popUpDisclaimers.servicePlan;
      }
      this.disableSubmit = false;
    }

    if (this.washRequest.arrivalTime > this.washRequest.needByTime) {
      this.actionDisclaimer =
        this.actionDisclaimer +
        `\n\n${popUpDisclaimers.needByTimeBeforeArrivalTime}`;
      this.disableSubmit = true;
    }

    if (this.isSteamRequest && this.washRequest.files.length === 0) {
      this.actionDisclaimer =
        this.actionDisclaimer + `\n\n${popUpDisclaimers.missingSDS}`;
    }
  }

  popUpDetails() {
    this.actionHeader = this.servicePlanPopUpInputModel.actionHeader;
    this.actionLabel = this.servicePlanPopUpInputModel.actionLabel;
    this.secondaryActionLabel =
      this.servicePlanPopUpInputModel.secondaryActionLabel;
    this.actionDisclaimer = this.servicePlanPopUpInputModel.actionDisclaimer;
    this.averageAutomatedConversionTime =
      this.servicePlanPopUpInputModel.averageAutomatedConversionTime;
    this.washRequestDetails();
  }

  checkIfCanSubmit() {
    this.disableSubmit = !this.servicePlanPopUpInputModel.canSubmit
      ? true
      : this.disableSubmit;
  }

  submitPreventingOrderConversion() {
    this.washRequestSubmitInProcess = true;
    this.dialogRef.close({ submit: true, preventOrderConversion: true });
  }

  submit() {
    this.washRequestSubmitInProcess = true;
    this.dialogRef.close({ submit: true });
  }

  closePopUp() {
    this.dialogRef.close({ submit: false });
  }
}
