import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ManualStartStopReasonFieldsEnum } from './manual-start-stop-reason-fields.enum';

@Component({
  selector: 'app-manual-start-stop-reason',
  templateUrl: './manual-start-stop-reason.component.html',
  styleUrls: ['./manual-start-stop-reason.component.scss'],
})
export class ManualStartStopReasonComponent implements OnInit {
  formGroup: FormGroup;
  comments: FormControl;
  formFieldsEnum = ManualStartStopReasonFieldsEnum;

  constructor(
    public dialogRef: MatDialogRef<ManualStartStopReasonComponent>,
    private formBuilder: FormBuilder
  ) {
    this.createFormControls();
    this.createFormGroup();
  }

  ngOnInit(): void {}

  save() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        additionalComments: this.comments.value,
        code: 'OTHER',
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  private createFormControls() {
    this.comments = new FormControl('', Validators.required);
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group({
      comments: this.comments,
    });
  }
}
