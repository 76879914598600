export enum WashUpdateFieldsEnum {
  customerId = 'Company Name',
  arrivalDate = 'Arrival Date',
  arrivalTime = 'Arrival Time',
  needByDate = 'Need by Date',
  needByTime = 'Need by Time',
  containerId = 'Container Number',
  serviceTypeId = 'Service Type',
  serviceTypeOntraxId = 'Service Type',
  foodGrade = 'Food Grade',
  containerTypeId = 'Container type',
  compartmentsQty = 'Number of Compartments',
  nitrogen = 'Contains Nitrogen',
  poNumber = 'PO Number',
  containerOwnerId = 'Container Owner',
  washBillToTerminalId = 'Wash Bill to Terminal',
  lastContainedProduct1Id = 'Last Contained Product 1',
  lastContainedProduct2Id = 'Last Contained Product 2',
  lastContainedProduct3Id = 'Last Contained Product 3',
  lastContainedProductComp1Id = 'Last Contained - Compartment 1',
  lastContainedProductComp2Id = 'Last Contained - Compartment 2',
  lastContainedProductComp3Id = 'Last Contained - Compartment 3',
  lastContainedProductComp4Id = 'Last Contained - Compartment 4',
  lastContainedProductComp5Id = 'Last Contained - Compartment 5',
  warehouseId = 'Terminal',
  container = 'Container',
  type = 'Type Of Heat',
  minTemperature = 'Minimum Temperature',
  maxTemperature = 'Maximum Temperature',
}

export interface IFieldsState {
  foodGrade?: {
    disabled: boolean;
  };
}
