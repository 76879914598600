import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ScheduleRoutingModule } from './schedule-routing.module';
import { ScheduleComponent } from './schedule.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CardComponent } from './card/card.component';
import { AgendaComponent } from './agenda/agenda.component';
import { SlotComponent } from './agenda/slot/slot.component';
import { WashConvertComponent } from './wash-convert-component/wash-convert.component';
import { ManualScheduleOverlayComponent } from './manual-schedule-overlay/manual-schedule-overlay.component';
import { HeaderComponent } from './header/header.component';
import { MissedNeedByTimeReportComponent } from './missed-need-by-time-report/missed-need-by-time-report.component';
import { StartStopConfirmationComponent } from './start-stop-confirmation/start-stop-confirmation.component';
import { ManualStartStopReasonComponent } from './start-stop-confirmation/manual-start-stop-reason/manual-start-stop-reason.component';
import { CardInfoBarComponent } from './card/card-info-bar/card-info-bar.component';

@NgModule({
  declarations: [
    ScheduleComponent,
    InProgressComponent,
    CardComponent,
    AgendaComponent,
    SlotComponent,
    WashConvertComponent,
    ManualScheduleOverlayComponent,
    HeaderComponent,
    MissedNeedByTimeReportComponent,
    StartStopConfirmationComponent,
    ManualStartStopReasonComponent,
  ],
  imports: [
    SharedModule,
    ScheduleRoutingModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SatPopoverModule,
    MatAutocompleteModule,
    CardInfoBarComponent,
  ],
})
export class ScheduleModule {}
