import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { OperatorsDialogComponent } from './operators-dialog.component';
import { Operator } from '../schedule.model';

@Injectable({
  providedIn: 'root',
})
export class OperatorsDialogService {
  private dialogBehaviourSubject = new BehaviorSubject(false);
  dialogResult = this.dialogBehaviourSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog(operators: Operator[], recentOperators: Operator[]) {
    const dialogRef = this.dialog.open(OperatorsDialogComponent, {
      data: { operators, recentOperators },
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
